import { IContext } from './types';

const initialState: IContext = {
  loading: { urls: [] },
  admin: {},
  token: '',
  company: {},
  salesCompany: {},
  dispatchAction: () => null,
};

export { initialState };
