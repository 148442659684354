import { Authentication } from '@flash-hros/auth-helper';
import { useState, useEffect } from 'react';

const { CognitoAuthenticatedUser } = Authentication;

interface CognitoUser {
  username: string;
  attributes: {
    name?: string;
    phone_number: string;
    email: string;
    preferred_username: string;
  };
}

type HookResult = {
  fetchAuthenticatedUser: () => void;
  authenticatedUser: CognitoUser;
  error: boolean;
  loading: boolean;
};

export const useCognitoAuthenticatedUser = (): HookResult => {
  const [authenticatedUser, setAuthenticatedUser] = useState<CognitoUser>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAuthenticatedUser();
  }, []);

  const fetchAuthenticatedUser = async () => {
    setLoading(true);
    try {
      const user = await CognitoAuthenticatedUser({ bypassCache: true });
      setAuthenticatedUser(user);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return {
    fetchAuthenticatedUser,
    authenticatedUser: authenticatedUser!,
    error,
    loading,
  };
};
