import { ErrorBoundary } from './ErrorBoundary';

export const parseFeatureFlagPayload = <T>(value: string): T | undefined => {
  if (!value) return;

  try {
    const parsedPayload = JSON.parse(value);
    return parsedPayload as T;
  } catch (e: any) {
    ErrorBoundary.captureException(e);
    return;
  }
};
