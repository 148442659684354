import { Button, Modal } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const ModalContent = styled(Modal.Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs2};

  @media screen and (max-width: 640px) {
    padding-left: ${({ theme }) => theme.spacings.s};
    padding-right: ${({ theme }) => theme.spacings.s};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
`;

export const StyledButton = styled(Button)`
  width: 220px;
`;
