import { CircularProgress } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageFooter } from '../../components/Footer';
import { ProgressBar } from '../../components/ProgressBar';
import { ProgressTab } from '../../components/ProgressTab';

import {
  MainContainer,
  HeaderContainer,
  FlexContainer,
  StyledLogo,
  ContentContainer,
  Footer,
} from './sales.styles';
import { useGetSalesCompany } from '@/pages/SignUp/hooks/use-get-sales-company';
import { useGetContractByCompanyId } from './sales.hooks';

export const Sales = () => {
  const navigate = useNavigate();
  const { getSalesCompany } = useGetSalesCompany();
  const { getSalesContractByCompanyId } = useGetContractByCompanyId();
  const [salesCompany, setSalesCompany] = useState({});

  const { hash } = useParams();
  const decodedHash = atob(hash!);
  const ids = decodedHash.split('#');

  const refToTop = useRef<HTMLInputElement>(null);
  useEffect(() => {
    refToTop.current?.scrollIntoView({ behavior: 'smooth' });
  });

  useEffect(() => {
    (async () => {
      const response = await getSalesCompany({
        companyId: ids[0],
      });

      if (response.value) {
        setSalesCompany(response.value);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const contractById = await getSalesContractByCompanyId({
        companyId: ids[0],
      });

      const peopleContract =
        contractById.value?.find((c) =>
          c.product.some((p) => p.businessUnity == 'people'),
        ) || {};

      if (peopleContract?.status == 'waiting_signup') {
        navigate(`/signup/sales/create-company/${ids[0]}/${ids[1]}`);
      } else {
        navigate(`/signup/self/error`);
      }
    })();
  }, [salesCompany]);

  return (
    <MainContainer>
      <HeaderContainer ref={refToTop}>
        <>
          <FlexContainer>
            <StyledLogo />
            <ProgressTab selectedPage="" />
          </FlexContainer>
          <ProgressBar current={0} />
        </>
      </HeaderContainer>

      <ContentContainer>
        <CircularProgress />
      </ContentContainer>

      <Footer>
        <PageFooter />
      </Footer>
    </MainContainer>
  );
};
