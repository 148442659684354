import { useParams } from 'react-router-dom';

import { FlexContainer, StyledLogo } from '../signup.styles';
import { ProgressBar, ProgressTab, SectionLoading } from '@/components';

interface SignupProgressbarProps {
  loading: boolean;
  hasPhoneConfirmation?: boolean;
  hasCommercialCondition?: boolean;
  currentStep: number;
}

const SignUpProgressBar = ({
  loading,
  hasPhoneConfirmation = true,
  hasCommercialCondition = false,
  currentStep,
}: SignupProgressbarProps) => {
  const { step } = useParams();
  const steps =
    2 + [hasCommercialCondition, hasPhoneConfirmation].filter(Boolean).length;
  return (
    <>
      <FlexContainer>
        <StyledLogo />
        <SectionLoading key={'progressTab'} url="getRegistrationNumberByDealId">
          {({ loading: sectionLoading }) =>
            loading || sectionLoading ? (
              <></>
            ) : (
              <ProgressTab
                selectedPage={step!}
                hasCommercialConditions={hasCommercialCondition}
                hasPhoneConfirmation={hasPhoneConfirmation}
              />
            )
          }
        </SectionLoading>
      </FlexContainer>
      <ProgressBar current={currentStep} steps={steps} />
    </>
  );
};

export default SignUpProgressBar;
