import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';
interface exempt {
  exempt: boolean;
}
export const ExemptIconText = styled(Typography as any).attrs({
  variant: 'caption',
})`
  color: ${({ theme }) => theme.colors.feedback.success[10]};
  margin-bottom: 4px;
`;

export const ExemptContainer = styled.div`
  display: flex;
  justify-content: space-between;

  align-items: center;
  gap: 8px;
  color: #86797f;
`;
export const ExemptText = styled.div<exempt>`
  ${(props) => {
    let customStyle = {};
    if (props.exempt) {
      customStyle = {
        textDecoration: 'line-through',
        textDecorationColor: '#86797F',
      };
    }
    return customStyle;
  }}
`;
export const ExemptIcon = styled.div`
  padding: 2px 12px;
  text-align: center;
  border-radius: 24px;
  font-size: 12px;
  background-color: #d7f9f3;
`;
