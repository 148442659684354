import {
  MonitoringErrorBoundary,
  MonitoringManager,
} from '@flash-tecnologia/hros-web-utility';
import { MonitoringLevel } from '@flash-tecnologia/hros-web-utility/dist/monitoring/MonitoringLevel';

const projectDsn =
  'https://168e071ce0e71df2ecf01e2523568bed@o266934.ingest.sentry.io/4505676244254725';

export class ErrorBoundary extends MonitoringErrorBoundary {
  project: string = projectDsn;

  constructor(props) {
    super(props);
  }

  static captureException(
    error: Error,
    severity?: MonitoringLevel,
    extras?: Record<string, any>,
  ) {
    MonitoringManager.captureException(projectDsn, error, severity, extras);
  }
}
