import { Dispatch } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Authentication } from '@flash-hros/auth-helper';
import { Axios, setInLS, removeFromLS } from '@flash-hros/utility';
import { EActions, IActions } from '../context/actions';
import { dispatchToast } from '../utils';

const { CognitoSignUp, CognitoConfirmSignUp, CognitoResendSignUpCode } =
  Authentication;

interface ObjectLiteral {
  [key: string]: any;
}
interface SignUpProps {
  password: string;
  email?: string;
  phone_number: string;
  name: string;
  documentNumber: string;
  dispatch: Dispatch<IActions>;
  callback?: any;
}

export const SignUp = async ({
  password,
  email,
  phone_number,
  name,
  documentNumber,
  dispatch,
  callback,
}: SignUpProps) => {
  try {
    removeFromLS({ key: 'signUpUser' });

    const username = uuidv4();

    const response = await CognitoSignUp({
      username,
      password,
      email,
      phone_number,
      name,
    });

    let smsSentDestination = response?.codeDeliveryDetails?.Destination || '';
    smsSentDestination = smsSentDestination.replace(/\D/g, '');

    setInLS({
      key: 'signUpUser',
      value: {
        user: response?.user,
        smsInfo: smsSentDestination,
        preferredUserName: documentNumber,
      },
    });

    if (typeof callback === 'function') callback.call();

    dispatch({
      type: EActions.SYSTEM_LOADING_END,
      payload: 'cognitoSignUp',
    });

    return response;
  } catch (err) {
    dispatch({
      type: EActions.SYSTEM_LOADING_END,
      payload: 'cognitoSignUp',
    });

    dispatchToast({
      type: 'error',
      content: 'Ops! Erro ao processar dados, favor tentar novamente!',
    });
  }
};

interface ConfirmSignUpProps {
  username: string;
  code: string;
  options?: ObjectLiteral;
  dispatch: Dispatch<IActions>;
  callback?: any;
}

export const ConfirmSignUp = async ({
  username,
  code,
  options = {
    forceAliasCreation: false,
  },
  dispatch,
  callback,
}: ConfirmSignUpProps) => {
  try {
    dispatch({
      type: EActions.SYSTEM_LOADING_START,
      payload: 'cognitoSignUp',
    });

    const response = await CognitoConfirmSignUp({
      username,
      code,
      options,
    });

    removeFromLS({ key: 'temporaryUserInfo' });

    if (typeof callback === 'function') callback.call();

    return response;
  } catch (err: any) {
    let message = 'Ops! Erro ao processar dados, favor tentar novamente!';
    dispatch({
      type: EActions.SYSTEM_LOADING_END,
      payload: 'cognitoSignUp',
    });
    if (err.code) {
      switch (err.code) {
        case 'CodeMismatchException':
          message = 'Código inserido é inválido, tente novamente!';
          break;
        case 'ExpiredCodeException':
          message =
            'Código inserido foi expirado, favor reenviar um novo código!';
          break;
        case 'AliasExistsException':
          message =
            'Email/Telefone já vinculado a outra conta, favor contatar o atendimento.';
          break;
        default:
          message = 'Ops! Erro ao processar dados, favor tentar novamente!';
          break;
      }
    }

    dispatchToast({
      type: 'error',
      content: message,
    });

    dispatch({
      type: EActions.SYSTEM_LOADING_END,
      payload: 'cognitoSignUp',
    });
  }
};

export const isInvitationValid = async ({
  code,
  dispatch,
  callback,
}: {
  code: string;
  dispatch: Dispatch<IActions>;
  callback?: any;
}) => {
  try {
    dispatch({
      type: EActions.SYSTEM_LOADING_START,
      payload: 'isInvitationValid',
    });

    const { data } = await Axios({
      service: 'accessManagement',
      method: 'get',
      url: `/invitations/code/${code}`,
      axiosOptions: { noHeaders: true },
    });

    dispatch({
      type: EActions.SYSTEM_LOADING_END,
      payload: 'isInvitationValid',
    });

    if (typeof callback === 'function') callback.call(null, data?.isValid);

    if (!data?.isValid) {
      dispatchToast({
        type: 'error',
        content: 'Ops! Convite de acesso a plataforma inválido ou expirado!',
      });
    }

    return data?.isValid || false;
  } catch (err) {
    dispatchToast({
      type: 'error',
      content: 'Ops! Erro ao validar convite, favor tentar novamente!',
    });

    dispatch({
      type: EActions.SYSTEM_LOADING_END,
      payload: 'isInvitationValid',
    });

    if (typeof callback === 'function') callback.call(null, false);

    throw err;
  }
};

interface ResendSignUpProps {
  username: string;
  dispatch: Dispatch<IActions>;
  callback?: any;
}

export const ResendSignUpCode = async ({
  username,
  dispatch,
  callback,
}: ResendSignUpProps) => {
  try {
    dispatch({
      type: EActions.SYSTEM_LOADING_START,
      payload: 'cognitoResendSignUpCode',
    });

    const response = await CognitoResendSignUpCode({
      username,
    });

    if (typeof callback === 'function') callback.call();

    dispatch({
      type: EActions.SYSTEM_LOADING_END,
      payload: 'cognitoResendSignUpCode',
    });

    dispatchToast({
      type: 'success',
      content:
        'Código reenviado com sucesso!, Confira o código enviado em seu dispositivo',
    });

    return response;
  } catch (err) {
    dispatch({
      type: EActions.SYSTEM_LOADING_END,
      payload: 'cognitoResendSignUpCode',
    });

    dispatchToast({
      type: 'error',
      content: 'Ops! Erro ao reenviar código, favor tentar novamente!',
    });
  }
};

export const LoadingEnd = (dispatch) => {
  dispatch({
    type: EActions.SYSTEM_LOADING_END,
    payload: 'cognitoSignUp',
  });
};
