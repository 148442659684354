enum EActions {
  SYSTEM_LOADING_START = 'system_loading_start',
  SYSTEM_LOADING_END = 'system_loading_end',
  UPDATE_COMPANY = 'update_company',
  UPDATE_ADMIN = 'update_admin',
  UPDATE_TOKEN = 'update_token',
  SALES_COMPANY = 'sales_company',
}

interface IActions {
  type: EActions;
  payload?: any;
}

export { IActions, EActions };
