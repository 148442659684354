import { useCognitoAuthenticatedUser } from '@/utils/hooks/use-authenticated-user';
import { removeFromSS, setInSS } from '@flash-tecnologia/hros-web-utility';
import { trackEvent, validateCNPJ } from '@utils';
import { useFormik } from 'formik';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import * as yup from 'yup';

const validationSchema = yup.object({
  registrationNumber: yup
    .string()
    .required('Por favor, digite o CNPJ da empresa'),
  legalName: yup.string().required('Por favor, digite o nome da empresa'),
  employeesQuantity: yup.string(),
  zipCode: yup
    .string()
    .min(9, 'O CEP deve conter 8 dígitos')
    .required('Por favor, digite o CEP da empresa'),
  street: yup.string().required('Por favor, digite o Logradouro da empresa'),
  number: yup.string().required('Por favor, digite o Número da empresa'),
  district: yup.string().required('Por favor, digite a Cidade da empresa'),
  state: yup
    .string()
    .min(2, 'O Estado deve conter 2 caracteres')
    .required('Por favor, digite o Estado da empresa'),
  city: yup.string().required('Por favor, digite o Cidade da empresa'),
  termChecked: yup.boolean().isTrue(),
});

export type CompanyInfoFormValues = {
  registrationNumber: string;
  legalName: string;
  name: string;
  employeesQuantity: string;
  zipCode: string;
  street: string;
  number: string;
  complement: string;
  district: string;
  state: string;
  city: string;
  termChecked: boolean;
};

const initialValues: CompanyInfoFormValues = {
  registrationNumber: '',
  legalName: '',
  name: '',
  employeesQuantity: '',
  zipCode: '',
  street: '',
  number: '',
  complement: '',
  district: '',
  state: '',
  city: '',
  termChecked: false,
};

type CompanyInfoFormProps = {
  dealId: string;
  companyId: string;
  contractId: string;
  leadId: string;
  isSales: boolean;
};

export const useCompanyInfoForm = ({
  isSales,
  companyId,
  contractId,
  dealId,
  leadId,
}: Partial<CompanyInfoFormProps>) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { authenticatedUser } = useCognitoAuthenticatedUser();

  const form = useFormik({
    initialValues,
    validationSchema,
    initialErrors: {},
    validate: (values) => {
      let errors: any = {};
      if (values.registrationNumber && !validateCNPJ(values.registrationNumber))
        errors.registrationNumber = 'Por favor, digite um CNPJ válido.';
      if (values.employeesQuantity && isNaN(Number(values.employeesQuantity)))
        errors.employeesQuantity = 'Por favor, digite uma quantidade valida.';
      return errors;
    },
    onSubmit: async (values) => {
      trackEvent('signup_create_company_continue_clicked');
      removeFromSS({ key: 'temporaryCompanyInfos' });
      setInSS({
        key: 'temporaryCompanyInfos',
        value: {
          registrationNumber: values.registrationNumber.replace(/\D/g, ''),
          legalName: values.legalName,
          name: values.name,
          employeesQuantity: values.employeesQuantity,
          address: {
            zipCode: values.zipCode.replace(/\D/g, ''),
            street: values.street,
            number: values.number,
            complement: values.complement,
            district: values.district,
            city: values.city,
            state: values.state,
          },
        },
      });

      if (isSales) {
        navigate(`/signup/sales/create-admin/${companyId}/${contractId}`);
        return;
      }

      if (!!leadId) {
        navigate({
          pathname: '/authentication/first-access',
          search: createSearchParams({
            redirectTo: `/signup/trial/${leadId}/admin-info`,
          }).toString(),
        });
        return;
      }

      if (!!dealId && !authenticatedUser) {
        navigate({
          pathname: '/authentication/first-access',
          search: createSearchParams({
            redirectTo: `/signup/hubspot/${dealId}/admin-info?${searchParams.toString()}`,
          }).toString(),
        });
        return;
      }

      if (!!dealId && authenticatedUser) {
        navigate({
          pathname: `/signup/hubspot/${dealId}/admin-info`,
          search: searchParams.toString(),
        });
        return;
      }

      if (authenticatedUser) {
        navigate({
          pathname: '/signup/self/admin-info',
          search: searchParams.toString(),
        });
        return;
      }

      navigate({
        pathname: '/authentication/first-access',
        search: createSearchParams({
          redirectTo: `/signup/self/admin-info?${searchParams.toString()}`,
        }).toString(),
      });
    },
  });

  return { form };
};
