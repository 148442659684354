import { TextField, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const MainContainer = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 535px;
  gap: ${({ theme }) => theme.spacings.m};
`;

export const ConfirmationContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacings.m};

  @media screen and (min-width: 781px) {
    margin-bottom: ${({ theme }) => theme.spacings.m};
  }
`;

export const TermText = styled.span`
  color: ${({ theme }) => theme.colors.secondary[50]};
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 781px) {
    padding-bottom: ${({ theme }) => theme.spacings.m};
  }
`;

export const StyledInput = styled(TextField)`
  margin-top: ${({ theme }) => theme.spacings.xs};
`;

export const RequiredText = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacings.xs2};
`;

export const HelperTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs5};
  padding-left: ${({ theme }) => theme.spacings.xs2};
`;
