import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  getFromLS,
  removeFromSS,
  setInLS,
  getFromSS,
} from '@flash-hros/utility';

import {
  Button,
  Typography,
  LinkButton,
  Icons,
  dayjs,
} from '@flash-tecnologia/hros-web-ui-v2';

import {
  MainContainer,
  OTPContainer,
  ButtonsArea,
  OptionsContainer,
  OptionsField,
  MessageContainer,
  StyledOTP,
  AwaitContainer,
  AwaitText,
} from './styles';
import {
  ConfirmSignUp,
  isInvitationValid,
  LoadingEnd,
  ResendSignUpCode,
} from '../../../../api';
import { Context } from '../../../../context';
import { FluxEndModal } from './FluxEndModal';
import { Countdown } from './Countdown';
import {
  ErrorText,
  HelperTextContainer,
  StyledIcon,
} from '../PersonalInfo/styled';
import { SectionLoading } from '../../../../components/SectionLoading';
import { useUpdateContractStatus } from '../../hooks/use-update-contract-status';
import { trackEvent, trackPage } from '@utils';
import { useActiveCompany } from '../PersonalInfo/personal-info-hooks';

const validationSchema = yup.object({
  otpNumber: yup
    .string()
    .min(6, 'Por favor, preencha todos os campos')
    .required('Por favor, digite o código recebido via SMS'),
});

export const PhoneAuthentication = ({
  isSales,
  companyId,
  contractId,
  dealId,
  leadId,
  hasBenefitsProducts,
}) => {
  const navigate = useNavigate();
  const { updateContractStatus } = useUpdateContractStatus();
  const { activateCompany } = useActiveCompany();

  const signUpUser = useMemo(() => getFromLS('signUpUser'), []);
  const lastAttemptError = useMemo(() => getFromSS('lastAttemptError'), []);
  const { dispatch } = useContext(Context);
  const [openModal, setOpenModal] = useState(false);
  const [awaitBlock, setAwaitBlock] = useState(true);
  const [timer, setTimer] = useState(60);
  const { invitationCode, companyId: company_Id } = useParams();

  useEffect(() => {
    trackPage('signup_phone_authentication_page');
  }, []);

  useEffect(() => {
    if (invitationCode === undefined) return navigate(`/authentication/login`);
    (async () => {
      const response = await isInvitationValid({
        code: invitationCode,
        dispatch: dispatch!,
      });
      if (!response) return navigate(`/authentication/login`);
    })();
  }, [invitationCode]);

  const handleIsInvitationValid = useCallback(async () => {
    try {
      const isValid = await isInvitationValid({
        code: invitationCode!,
        dispatch: dispatch!,
      });

      return isValid;
    } catch (err) {
      return false;
    }
  }, [invitationCode, dispatch]);

  const handleSignInChanged = useCallback(({ detail }: CustomEvent) => {
    LoadingEnd(dispatch);
    if (!detail || !detail.status) return;
    if (detail.status === 'success') {
      if (hasBenefitsProducts) {
        navigate(`/signup/hubspot/${dealId}/commercial-conditions`);
      } else {
        setOpenModal(true);
      }
    }
    if (detail.status === 'error') navigate('/authentication/login');
  }, []);

  useEffect(() => {
    window.addEventListener('signInStatus' as any, handleSignInChanged);
    return () => {
      window.removeEventListener('signInStatus' as any, handleSignInChanged);
    };
  }, []);

  const keepBlockResend = (lastAttempt: Date) => {
    const verifyHour = dayjs();
    const dateAttempt = dayjs(lastAttempt).add(60, 'second');
    dateAttempt.isAfter(verifyHour)
      ? setTimer(dayjs(dateAttempt).diff(verifyHour, 'seconds'))
      : '';
    return dateAttempt.isAfter(verifyHour);
  };

  const handleGoBackOnClick = () => {
    switch (true) {
      case isSales:
        navigate(`/signup/sales/create-admin/${companyId}/${contractId}`);
        break;
      case dealId != undefined:
        navigate(`/signup/hubspot/${dealId}/create-admin/`);
        break;
      case leadId != undefined:
        navigate(`/signup/trial/${leadId}/create-admin/`);
        break;
      default:
        navigate(`/signup/self/create-admin`);
        break;
    }
  };
  useEffect(() => {
    if (!lastAttemptError) return setAwaitBlock(false);
    if (keepBlockResend(lastAttemptError)) setAwaitBlock(true);
    else removeFromSS({ key: 'lastAttemptError' });
  }, []);
  const formik = useFormik({
    initialValues: {
      otpNumber: '',
    },

    validationSchema: validationSchema,

    onSubmit: async (values) => {
      trackEvent('signup_phone_authentication_continue_clicked');

      const isValid = await handleIsInvitationValid();
      if (!isValid) return;

      await ConfirmSignUp({
        username: signUpUser?.user?.username,
        code: values.otpNumber,
        dispatch: dispatch!,
        callback: async () => {
          if (isSales) {
            await updateContractStatus({
              contractId,
              data: { status: 'waiting_signup' },
            });
          }
          await activateCompany({
            companyId: company_Id ? company_Id : companyId,
          });

          setInLS({ key: 'invitationCode', value: invitationCode });
          if (!hasBenefitsProducts) {
            removeFromSS({ key: 'temporaryUserInfo' });
            removeFromSS({ key: 'temporaryCompanyInfos' });
          }
        },
      });
    },
  });

  return (
    <MainContainer>
      <MessageContainer>
        <Typography
          variant={'headline4'}
          children={'Confirme seu telefone de cadastro'}
          style={{
            fontSize: '40px',
          }}
        />
        <div style={{ marginTop: '14px' }}>
          <Typography
            variant={'body3'}
            style={{
              color: '#86797f',
            }}
          >
            Cadastrar um telefone válido é importante para recebimento de avisos
            e garante sua segurança em casos de recuperação de conta.
          </Typography>
        </div>
      </MessageContainer>
      <OTPContainer>
        <Typography
          variant={'body3'}
          children={`Insira abaixo o código recebido para confirmar seu telefone.`}
          style={{
            color: '#6B5B66',
          }}
        />
        <StyledOTP
          numInputs={6}
          id="otpNumber"
          name="otpNumber"
          value={formik.values.otpNumber}
          onChange={(value) => {
            formik.handleChange({ target: { id: 'otpNumber', value } });
          }}
          error={formik.touched.otpNumber && Boolean(formik.errors.otpNumber)}
        />
        {formik.touched.otpNumber && formik.errors.otpNumber && (
          <HelperTextContainer>
            <StyledIcon size={15} name="IconAlertCircle" fill="none" />
            <ErrorText variant="body4">{formik.errors.otpNumber}</ErrorText>
          </HelperTextContainer>
        )}
      </OTPContainer>
      <OptionsContainer>
        <OptionsField>
          <Typography
            variant={'headline3'}
            children={` Enviamos seu código por SMS para •••••${
              signUpUser?.smsInfo || ''
            }`}
            style={{
              fontSize: '16px',
              fontWeight: 'normal',
              lineHeight: '20px',
              borderBottom: '2px',
            }}
          />

          <LinkButton
            variant="primary"
            onClick={() => {
              handleGoBackOnClick();
            }}
          >
            Editar
            <Icons name="IconPencil" fill="none" />
          </LinkButton>
        </OptionsField>
        <OptionsField>
          <Typography
            variant={'headline3'}
            children={'Não recebeu seu código?'}
            style={{
              fontSize: '16px',
              fontWeight: 'normal',
              lineHeight: '20px',
              borderBottom: '2px',
            }}
          />
          {awaitBlock ? (
            <AwaitContainer>
              <Icons name="IconClock" fill="none" color="#83727D" />
              <AwaitText variant="body4">
                Aguarde{' '}
                <Countdown
                  time={timer}
                  onFinished={() => setAwaitBlock(false)}
                />{' '}
                para reenviar
              </AwaitText>
            </AwaitContainer>
          ) : (
            <LinkButton
              variant="primary"
              onClick={() => {
                ResendSignUpCode({
                  username: signUpUser?.user?.username,
                  dispatch: dispatch!,
                  callback: () => {
                    setAwaitBlock(true);
                  },
                });
              }}
              disabled={awaitBlock}
            >
              Reenviar
              <Icons name="IconArrowBack" fill="none" />
            </LinkButton>
          )}
        </OptionsField>
      </OptionsContainer>
      <ButtonsArea>
        <LinkButton
          variant={'primary'}
          onClick={() => {
            handleGoBackOnClick();
          }}
          children={'Voltar'}
          style={{ alignSelf: 'center' }}
        />
        <SectionLoading url={'cognitoSignUp'}>
          {({ loading }) => (
            <Button
              variant={'primary'}
              size="medium"
              children={'Concluir'}
              loading={loading}
              disabled={loading}
              onClick={() => {
                formik.handleSubmit();
              }}
              style={{ width: '250px' }}
            />
          )}
        </SectionLoading>
      </ButtonsArea>
      <FluxEndModal open={openModal} isTrial={!!leadId} />
    </MainContainer>
  );
};
