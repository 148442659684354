import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Button,
  LinkButton,
  PasswordField,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';

import { setInSS, removeFromSS, getFromSS } from '@flash-hros/utility';
import { SectionLoading } from '../../../../components/SectionLoading';

import { SignUp } from '../../../../api';
import { Context } from '../../../../context';

import {
  MainContainer,
  ButtonsArea,
  MessageContainer,
  InputsContainer,
  HelperTextContainer,
  ErrorText,
  StyledIcon,
  PasswordErrorText,
  PasswordErrorIcon,
  StyledInput,
} from './styled';
import {
  validateEmail,
  validateCPF,
  formatCompanySignupPhone,
  trackPage,
} from '../../../../utils';
import { useCognitoAuthenticatedUser } from '../../../../utils/hooks/use-authenticated-user';
import { useCompanySignupMutation } from 'src/pages/SignUp/hooks/use-company-signup';

const validationSchema = yup.object({
  name: yup.string().required('Por favor, digite o nome do administrador'),
  documentNumber: yup
    .string()
    .required('Por favor, digite o CPF do administrador'),
  phone: yup
    .string()
    .max(16, 'O número deve conter contem 11 digitos')
    .required('Por favor, digite o número de celular do administrador'),
  password: yup
    .string()
    .required(`Por favor, digite uma senha`)
    .min(8, 'A senha deve conter 8 ou mais caracteres')
    .matches(/[0-9]/, 'A senha deve conter número')
    .matches(/[a-z]/, 'A senha deve conter ao menos uma letra minúscula')
    .matches(/[A-Z]/, 'A senha deve conter ao menos uma letra maiúscula')
    .matches(/[^\w]/, 'A senha deve conter ao menos um caracter especial '),
  email: yup.string().required('Por favor, digite o E-mail do administrador'),
  confirmPassword: yup.string().required('Por favor, confirme sua senha'),
});

export const PersonalInfo = ({
  isSales,
  companyId,
  contractId,
  dealId,
  leadId,
  signupType,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { companySignUp } = useCompanySignupMutation();

  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [lengthCheck, setLengthCheck] = useState(false);
  const { authenticatedUser, loading: cognitoLoading } =
    useCognitoAuthenticatedUser();
  const { pathname } = useLocation();
  const { dispatch } = useContext(Context);

  useEffect(() => {
    if (authenticatedUser) {
      navigate(pathname.replace('create-admin', 'admin-info'));
    }
  }, [authenticatedUser]);

  const temporaryUserInfo = useMemo(() => getFromSS('temporaryUserInfo'), []);
  const temporaryCompanyInfos = useMemo(
    () => getFromSS('temporaryCompanyInfos'),
    [],
  );

  const handleGoBackOnClick = () => {
    switch (true) {
      case isSales:
        navigate(`/signup/sales/create-company/${companyId}/${contractId}`);
        break;
      case dealId != undefined:
        navigate(`/signup/hubspot/${dealId}/create-company/`);
        break;
      case leadId != undefined:
        navigate(`/signup/trial/${leadId}/create-company/`);
        break;
      default:
        navigate({
          pathname: `/signup/self/create-company`,
          search: searchParams.toString(),
        });
        break;
    }
  };
  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      documentNumber: '',
      phone: '',
      password: '',
      confirmPassword: '',
    },

    validationSchema: validationSchema,

    validate: (values) => {
      let errors: any = {};

      if (values.documentNumber && !validateCPF(values.documentNumber))
        errors.documentNumber = 'Por favor, digite um CPF válido.';
      if (values.email && !validateEmail(values.email))
        errors.email = 'Por favor, digite um e-mail válido.';
      if (
        values.password?.length >= 8 &&
        values.confirmPassword != values.password
      )
        errors.confirmPassword = 'Por favor, confirme a senha novamente';
      return errors;
    },

    onSubmit: async (values) => {
      values.documentNumber = values.documentNumber.replace(/\D/g, '');
      removeFromSS({ key: 'temporaryUserInfo' });
      setInSS({
        key: 'temporaryUserInfo',
        value: {
          email: values.email,
          phone_number: values.phone,
          name: values.name,
          documentNumber: values.documentNumber,
        },
      });

      const phone = formatCompanySignupPhone(values.phone);
      await companySignUp({
        employeeInfo: {
          name: values.name,
          documentNumber: values.documentNumber,
          email: values.email,
          phone,
        },
        companyInfo: {
          registrationNumber: temporaryCompanyInfos?.registrationNumber,
          legalName: temporaryCompanyInfos?.legalName,
          name: temporaryCompanyInfos?.name || '',
          address: {
            zipCode: temporaryCompanyInfos?.address.zipCode,
            street: temporaryCompanyInfos?.address.street,
            number: temporaryCompanyInfos?.address.number,
            complement: temporaryCompanyInfos?.address.complement || '',
            district: temporaryCompanyInfos?.address.district,
            city: temporaryCompanyInfos?.address.city,
            state: temporaryCompanyInfos?.address.state,
          },
        },
        dealId,
        leadId,
      });

      const response = await SignUp({
        password: values.password,
        email: values.email,
        phone_number: phone,
        name: values.name,
        documentNumber: values.documentNumber,
        dispatch: dispatch!,
      });

      switch (true) {
        case isSales:
          navigate(
            `/signup/sales/validate-phone/${companyId}/${contractId}/${response?.value.invite?.code}`,
          );
          break;
        case dealId != undefined:
          navigate(
            `/signup/hubspot/${dealId}/validate-phone/${response?.value?.invite?.code}/${response?.value?.company?._id}`,
          );
          break;
        case leadId != undefined:
          navigate(
            `/signup/trial/${leadId}/validate-phone/${response?.value?.invite?.code}/${response?.value?.company?._id}`,
          );
          break;
        default:
          navigate(
            `/signup/self/validate-phone/${response?.value?.invite?.code}/${response?.value?.company?._id}`,
          );
          break;
      }
    },
  });

  useEffect(() => {
    trackPage('signup_personal_info_page');
  }, []);

  useEffect(() => {
    if (temporaryUserInfo === undefined) return;

    formik.setValues({
      email: temporaryUserInfo.email,
      name: temporaryUserInfo.name,
      documentNumber: temporaryUserInfo.documentNumber,
      phone: temporaryUserInfo.phone_number,
      password: '',
      confirmPassword: '',
    });
  }, [temporaryUserInfo]);

  useEffect(() => {
    formik.values.password.match(/[0-9]/)
      ? setHasNumber(true)
      : setHasNumber(false);
    formik.values.password.match(/[A-Z]/)
      ? setHasUpperCase(true)
      : setHasUpperCase(false);
    formik.values.password.match(/[a-z]/)
      ? setHasLowerCase(true)
      : setHasLowerCase(false);
    formik.values.password.length >= 8
      ? setLengthCheck(true)
      : setLengthCheck(false);
    formik.values.password.match(/[^\w]/)
      ? setHasSpecialChar(true)
      : setHasSpecialChar(false);
  }, [formik.values.password]);

  return (
    <MainContainer>
      <MessageContainer>
        <Typography
          style={{
            color: '#FE2B8F',
            fontSize: '16px',
            fontWeight: 'Bold',
            lineHeight: '16px',
            marginBottom: '18px',
          }}
          variant={'body1'}
          children={`Quero ser Flash`}
        />
        <Typography
          variant={'headline2'}
          children={'Crie seu acesso de administrador'}
          style={{
            fontSize: '40px',
            lineHeight: '40px',
            marginBottom: '18px',
            maxWidth: '305px',
          }}
        />
        <div style={{ marginTop: '14px' }}>
          <Typography
            variant={'body1'}
            children={`Os dados abaixo serão usados para acessar sua conta e gerenciar sua organização.`}
            style={{
              fontSize: '16px',
              lineHeight: '24px',
              color: '#86797f',
              maxWidth: '385px',
            }}
          />
        </div>
      </MessageContainer>
      {!cognitoLoading && (
        <>
          <InputsContainer>
            <StyledInput
              label="Nome Completo"
              id={'mothersName'}
              name={'name'}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
            />

            {formik.touched.name && formik.errors.name && (
              <HelperTextContainer>
                <StyledIcon size={15} name="IconAlertCircle" fill="none" />
                <ErrorText variant="body4">{formik.errors.name}</ErrorText>
              </HelperTextContainer>
            )}

            <StyledInput
              label="CPF"
              id={'documentNumber'}
              name={'documentNumber'}
              value={formik.values.documentNumber}
              onChange={formik.handleChange}
              imaskProps={{ mask: '000.000.000-00' }}
              error={
                formik.touched.documentNumber &&
                Boolean(formik.errors.documentNumber)
              }
            />
            {formik.touched.documentNumber && formik.errors.documentNumber && (
              <HelperTextContainer>
                <StyledIcon size={15} name="IconAlertCircle" fill="none" />
                <ErrorText variant="body4">
                  {formik.errors.documentNumber}
                </ErrorText>
              </HelperTextContainer>
            )}
            <StyledInput
              label="E-mail corporativo"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
            />
            {formik.touched.email && formik.errors.email && (
              <HelperTextContainer>
                <StyledIcon size={15} name="IconAlertCircle" fill="none" />
                <ErrorText variant="body4">{formik.errors.email}</ErrorText>
              </HelperTextContainer>
            )}
            <StyledInput
              label="Celular"
              id="phone"
              name="phone"
              value={formik.values.phone}
              imaskProps={{ mask: '(00) 0 0000-0000' }}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
            />
            {formik.touched.phone && formik.errors.phone && (
              <HelperTextContainer>
                <StyledIcon size={15} name="IconAlertCircle" fill="none" />
                <ErrorText variant="body4">{formik.errors.phone}</ErrorText>
              </HelperTextContainer>
            )}
            <PasswordField
              label="Senha"
              id="password"
              name="password"
              type="password"
              autoComplete="off"
              value={formik.values.password}
              onChange={formik.handleChange}
              style={{ marginTop: '24px' }}
              error={formik.touched.password && Boolean(formik.errors.password)}
            />
            {formik.touched.password && formik.errors.password && (
              <HelperTextContainer>
                <StyledIcon size={15} name="IconAlertCircle" fill="none" />
                <ErrorText variant="body4">{formik.errors.password}</ErrorText>
              </HelperTextContainer>
            )}
            <PasswordField
              id={'confirmPassword'}
              name={'confirmPassword'}
              label="Confirme sua senha"
              autoComplete="off"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              style={{ marginTop: '24px' }}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
              <HelperTextContainer>
                <StyledIcon size={15} name="IconAlertCircle" fill="none" />
                <ErrorText variant="body4">
                  {formik.errors.confirmPassword}
                </ErrorText>
              </HelperTextContainer>
            )}
            <HelperTextContainer>
              <PasswordErrorIcon
                size={15}
                name="IconAlertCircle"
                fill="none"
                hasError={!lengthCheck}
                style={{ marginTop: '5px' }}
              />
              <PasswordErrorText
                variant="body4"
                hasError={!lengthCheck}
                style={{ marginTop: '5px' }}
              >
                Mínimo 8 caracteres
              </PasswordErrorText>
            </HelperTextContainer>
            <HelperTextContainer>
              <PasswordErrorIcon
                size={15}
                name="IconAlertCircle"
                fill="none"
                hasError={!hasLowerCase}
              />
              <PasswordErrorText variant="body4" hasError={!hasLowerCase}>
                Ao menos 1 letra minúscula (abc)
              </PasswordErrorText>
            </HelperTextContainer>
            <HelperTextContainer>
              <PasswordErrorIcon
                size={15}
                name="IconAlertCircle"
                fill="none"
                hasError={!hasUpperCase}
              />
              <PasswordErrorText variant="body4" hasError={!hasUpperCase}>
                Ao menos 1 letra maiúsculas (ABC)
              </PasswordErrorText>
            </HelperTextContainer>
            <HelperTextContainer>
              <PasswordErrorIcon
                size={15}
                name="IconAlertCircle"
                fill="none"
                hasError={!hasNumber}
              />
              <PasswordErrorText variant="body4" hasError={!hasNumber}>
                Ao menos 1 número (123)
              </PasswordErrorText>
            </HelperTextContainer>
            <HelperTextContainer>
              <PasswordErrorIcon
                size={15}
                name="IconAlertCircle"
                fill="none"
                hasError={!hasSpecialChar}
              />
              <PasswordErrorText variant="body4" hasError={!hasSpecialChar}>
                Ao menos 1 caractere especial (@#$)
              </PasswordErrorText>
            </HelperTextContainer>
          </InputsContainer>

          <ButtonsArea>
            <LinkButton
              variant={'primary'}
              onClick={() => {
                handleGoBackOnClick();
              }}
              children={'Voltar'}
              style={{ alignSelf: 'center' }}
            />

            <SectionLoading url={'cognitoSignUp'}>
              {({ loading }) => (
                <Button
                  variant={'primary'}
                  children={'Continuar'}
                  size={'large'}
                  style={{ width: '250px' }}
                  loading={loading}
                  disabled={loading}
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                />
              )}
            </SectionLoading>
          </ButtonsArea>
        </>
      )}
    </MainContainer>
  );
};
