import { BackBar, LineContainer, FrontBar } from './styled';

interface ProgressBarProps {
  steps?: number;
  current?: number;
}

export const ProgressBar = (props: ProgressBarProps) => {
  const { steps = 3, current = 0 } = props;
  return (
    <LineContainer>
      <BackBar>
        <FrontBar steps={steps} current={current} />
      </BackBar>
    </LineContainer>
  );
};
