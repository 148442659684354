import styled from 'styled-components';
import FlashLogo from '../../assets/flashlogo.svg';

export const MainContainer = styled.div``;
export const HeaderContainer = styled.div``;
export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  position: fixed;
  top: 50%;
  height: 100%;
  width: 100%;

  @media screen and (max-width: 1000px) {
    padding: 60px;
  }
  @media screen and (max-width: 780px) {
    flex-direction: column;
    align-items: center;
  }
  @media screen and (max-width: 420px) {
    padding: 30px;
  }
`;
export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 24px;

  @media screen and (max-width: 660px) {
    flex-direction: column;
    padding: 10px 24px;
    gap: 10px;
  }
`;

export const StyledLogo = styled(FlashLogo)`
  width: 115px;
`;
