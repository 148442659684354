import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getS3Presigned } from '@flash-hros/utility';
import { Loader } from '@flash-tecnologia/hros-web-ui-v2';

const PreviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgb(254, 43, 143), rgb(255, 110, 96));
`;

export const ContractPreview = () => {
  const urlDinamicParams = useParams();
  const product = urlDinamicParams?.product;
  const path = {
    benefits: 'contracts/default/flash_benefits_company_term.pdf',
    expense: 'contracts/default/flash_expense_company_term.pdf',
    people: 'contracts/default/flash_people_company_term.pdf',
  };
  const [signedUrl, setSignedUrl] = useState('');

  useEffect(() => {
    const getUrl = async () => {
      await getS3Presigned({
        filename: path[product || 0],
        module: 'company-management',
      }).then(async (value) => {
        const { url = '' } = value;
        setSignedUrl(url);
      });
    };

    getUrl();
  }, []);

  useEffect(() => {
    if (signedUrl) window.location.replace(signedUrl);
  }, [signedUrl]);

  return (
    <PreviewContainer>
      <Loader size="large" variant="secondary" />
    </PreviewContainer>
  );
};
