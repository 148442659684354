import * as SC from './styles';

type BaseSectionProps = {
  id?: string;
  className?: string;
  children: React.ReactNode;
};

export const BaseSection = ({ className, id, children }: BaseSectionProps) => {
  return (
    <SC.Section className={className} id={id}>
      {children}
    </SC.Section>
  );
};
