import {
  CircularProgress,
  Icons,
  LinkButton,
  Modal,
  PDFViewer,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';

import ContractEmptyStateSVG from '../../../assets/contract-empty-state.svg';

import { FlexBetween, CenterContainer } from './styles';

interface ModalProps {
  open: boolean;
  url: string;
  onRetry: () => any;
  loading?: boolean;
  onClose?: () => any;
}

export const GeneralServicesTerm = ({
  loading = false,
  open,
  url,
  onRetry,
  onClose,
}: ModalProps) => {
  const showPdf = !!url && !loading;
  const showError = !url && !loading;

  return (
    <Modal.Root open={open} onClose={onClose!} style={{ overflow: 'auto' }}>
      <Modal.Content>
        <Typography variant="headline6" style={{ marginBottom: 8 }}>
          Contrato de Prestação de Serviços
        </Typography>
        <Typography variant="body3" variantColor="var(--color-neutral-50)">
          Para finalizar sua contratação, você deve ler atentamente e aceitar as
          condições comerciais do Contrato de Prestação de Serviços da Flash.
        </Typography>

        {showPdf && (
          <div
            style={{ width: '100%', maxWidth: '687px', marginBottom: '50px' }}
          >
            <Typography
              variant="body4"
              style={{ marginBottom: '24px', fontWeight: 'bold' }}
            >
              Leia abaixo
            </Typography>
            <PDFViewer
              width="100%"
              height="400px"
              src={url}
              options={{ showToolbar: true, fit: 'height', page: 1 }}
            />
          </div>
        )}

        {loading && (
          <CenterContainer>
            <CircularProgress
              variant="indeterminate"
              size={64}
              style={{ marginBottom: 16, alignSelf: 'center' }}
            />
            <Typography variant="body3" style={{ fontWeight: 700 }}>
              Carregando contrato
            </Typography>
            <Typography variant="body3" style={{ marginBottom: 16 }}>
              Aguarde um momento, estamos gerando o seu contrato.
            </Typography>
          </CenterContainer>
        )}

        {showError && (
          <CenterContainer>
            <ContractEmptyStateSVG
              style={{ marginBottom: 16, alignSelf: 'center' }}
            />
            <Typography variant="body3" style={{ fontWeight: 700 }}>
              Falha ao carregar contrato
            </Typography>
            <Typography variant="body3" style={{ marginBottom: 16 }}>
              Não conseguimos gerar o seu contrato.
            </Typography>
            <LinkButton
              variant="secondary"
              onClick={onRetry}
              style={{ alignSelf: 'center' }}
            >
              Tentar novamente <Icons fill="none" name="IconRefresh" />
            </LinkButton>
          </CenterContainer>
        )}
        <FlexBetween>
          <LinkButton
            variant="primary"
            onClick={onClose}
            style={{ alignSelf: 'center' }}
          >
            Fechar
          </LinkButton>
        </FlexBetween>
      </Modal.Content>
    </Modal.Root>
  );
};
