import { Typography, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 647px;

  background: #ffffff;
  border: 1px solid #ebe6e9;
  border-radius: 8px;

  margin: 150px auto;

  padding: 20px 150px 43px 150px;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 81px;
  height: 81px;

  border-radius: 50px;
  background-color: #ffe0ef;

  position: relative;
  top: -50px;
  margin-bottom: -10px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;

  max-width: 330px;

  text-align: center;
  margin-bottom: 20px;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};
`;
export const Text = styled(Typography)`
  color: ${({ theme }) => theme.colors.foreground};
`;
