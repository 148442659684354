import { EActions, IActions } from './actions';
import { initialState } from './store';
import { IContext } from './types';

const reducer = (state: IContext, action: IActions) => {
  switch (action.type) {
    case EActions.SYSTEM_LOADING_START:
      return {
        ...state,
        loading: {
          urls: []
            .concat(state.loading.urls as any, [action.payload] as any)
            .filter((u) => u),
        },
      };
    case EActions.SYSTEM_LOADING_END:
      return {
        ...state,
        loading: {
          urls: state.loading.urls.filter((u) => u !== action.payload),
        },
      };
    case EActions.UPDATE_COMPANY:
      return {
        ...state,
        company: action.payload,
      };
    case EActions.UPDATE_ADMIN:
      return {
        ...state,
        admin: action.payload,
      };
    case EActions.UPDATE_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case EActions.SALES_COMPANY:
      return {
        ...state,
        salesCompany: { ...action.payload },
      };

    default:
      throw new Error('Error in context reducer');
  }
};

export { reducer, initialState };
