import { CircularProgress } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageFooter } from '../../components/Footer';
import { ProgressBar } from '../../components/ProgressBar';
import { ProgressTab } from '../../components/ProgressTab';

import {
  MainContainer,
  HeaderContainer,
  FlexContainer,
  StyledLogo,
  ContentContainer,
  Footer,
} from './styled';
import { useBasicDealData } from '../SignUp/hooks/use-basic-deal-data';

export const Deal = () => {
  const { dealId } = useParams();
  const navigate = useNavigate();
  const { data, getBasicDealDataByHubspotId, isLoading } = useBasicDealData();

  useEffect(() => {
    if (dealId) {
      getBasicDealDataByHubspotId({ dealId });
    }
  }, []);

  const refToTop = useRef<HTMLInputElement>(null);

  useEffect(() => {
    refToTop.current?.scrollIntoView({ behavior: 'smooth' });
  });

  useEffect(() => {
    if (isLoading) return;

    if (data?.registrationNumber) {
      navigate(`/signup/hubspot/${dealId}/create-company`);
    } else {
      navigate(`/signup/self/error`);
    }
  }, [isLoading, data]);

  return (
    <MainContainer>
      <HeaderContainer ref={refToTop}>
        <>
          <FlexContainer>
            <StyledLogo />
            <ProgressTab selectedPage={''} />
          </FlexContainer>
          <ProgressBar current={0} />
        </>
      </HeaderContainer>

      <ContentContainer>
        <CircularProgress />
      </ContentContainer>

      <Footer>
        <PageFooter />
      </Footer>
    </MainContainer>
  );
};
