import { trpc } from '@api/client';
import { dispatchToast } from '@utils';

export const useGetContractByCompanyId = () => {
  const { mutateAsync, isLoading } =
    trpc.getSalesContractByCompanyId.useMutation({
      onError: () => {
        dispatchToast({
          type: 'error',
          content:
            'Não foi possível obter os dados do seu contrato. Por favor, tente novamente.',
        });
      },
    });

  return { getSalesContractByCompanyId: mutateAsync, isLoading };
};
