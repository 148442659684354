import styled from 'styled-components';
import { Typography as DSTypopgraphy } from '@flash-tecnologia/hros-web-ui-v2';
import { BaseSection } from '../../components';

const BannerImage =
  'https://images.flashapp.com.br/flash-os/signup/landing-page/banner.png';

export const Section = styled(BaseSection)`
  display: flex;
  justify-content: center;
  padding: unset;
`;

export const Banner = styled.div`
  position: relative;
  width: 100%;
  max-width: 1194px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs4};
  background: #fe2b8f;
  padding: 40px 40px 120px;

  @media screen and (max-width: 500px) {
    align-items: center;
    text-align: center;
  }

  @media screen and (min-width: 740px) {
    padding-bottom: 40px;
    margin: 40px 86px 0;
    border-radius: 32px;
    overflow: hidden;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url(${BannerImage});
    background-repeat: no-repeat;
    background-position: right;
    mask-image: linear-gradient(
      270deg,
      #fe2b8f 0%,
      #fe2b8f 48%,
      transparent 70%,
      transparent 100%
    );

    @media screen and (max-width: 1000px) {
      mask-image: linear-gradient(
        270deg,
        #fe2b8f 0%,
        #fe2b8f 5%,
        transparent 50%,
        transparent 100%
      );
    }

    @media screen and (max-width: 739px) {
      background-position: 90% 150%;
      mask-image: linear-gradient(
        0deg,
        #fe2b8f 0%,
        #fe2b8f 22%,
        transparent 49%,
        transparent 100%
      );
    }

    @media screen and (max-width: 500px) {
      background-position: 90% 135%;
    }
  }
`;

export const Typography = styled(DSTypopgraphy)`
  z-index: 1;
`;
