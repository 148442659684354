import { getFromSS, removeFromSS } from '@flash-tecnologia/hros-web-utility';
import { trackEvent } from '@utils';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useUpdateCompany } from '../../hooks/use-update-company';
import { useUpdateContractStatus } from '../../hooks/use-update-contract-status';
import { useGetCommercialConditions } from '../../hooks/use-get-commercial-conditions';
import { SignupType } from '../../signup.constants';
import { useSignContract } from '../../hooks/use-sign-contract';
import { useSearchParams } from 'react-router-dom';

const validationSchema = yup.object({
  contractCheck: yup.boolean(),
  isPatRegistered: yup.boolean().required(),
  patInscriptionNumber: yup.string().when('isPatRegistered', {
    is: true,
    then: yup
      .string()
      .required('Número de inscrição no PAT obrigatório.')
      .matches(
        /^\d+$/,
        'Número de inscrição no PAT inválido. Revise os dados preenchidos e tente novamente.',
      ),
  }),
});

export type CommercialConditionsFormValues = {
  contractId: string;
  contractCheck: boolean;
  isPatRegistered: boolean | null;
  patInscriptionNumber: string;
};

const initialValues: CommercialConditionsFormValues = {
  contractId: '',
  contractCheck: false,
  isPatRegistered: null,
  patInscriptionNumber: '',
};

type CommercialConditionsFormProps = {
  dealId: string;
  signupType: SignupType;
  onSuccess?: () => void;
};

export const useCommercialConditionsForm = ({
  signupType,
  dealId,
  onSuccess,
}: CommercialConditionsFormProps) => {
  const [searchParams] = useSearchParams();
  const { getCommercialConditions, isLoading } = useGetCommercialConditions();
  const { updateCompany, isLoading: isLoadingUpdateCompany } =
    useUpdateCompany();
  const { updateContractStatus, isLoading: isLoadingUpdateContractStatus } =
    useUpdateContractStatus();
  const { signContract, isLoading: isLoadingSignContract } = useSignContract();

  const form = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    validate: (values) => {
      if (!values.contractCheck) {
        return { contractCheck: true };
      }
    },
    onSubmit: async (values) => {
      trackEvent('signup_contract_accept_conclude_clicked');
      const temporaryCompanyInfos = getFromSS('temporaryCompanyInfos');
      const contractId =
        values.contractId ||
        searchParams.get('contractId') ||
        temporaryCompanyInfos.contractId;

      let signContractPromise;

      if (contractId) {
        signContractPromise = signContract({
          employeeId: temporaryCompanyInfos.employeeId,
          contractId,
        });
      } else {
        const commercialConditions = await getCommercialConditions({
          dealId,
          contractId,
        });

        signContractPromise = updateContractStatus({
          contractId: commercialConditions.contractId,
          data: { status: 'waiting_signup' },
        });
      }

      const updateCompanyPromise = updateCompany({
        companyId: temporaryCompanyInfos.companyId,
        registrationNumber: temporaryCompanyInfos.registrationNumber,
        legalName: temporaryCompanyInfos.legalName,
        pat: {
          isRegistered: !!values.isPatRegistered,
          registrationNumber: !!values.patInscriptionNumber
            ? values.patInscriptionNumber
            : undefined,
        },
      });

      const [signContractResult] = await Promise.allSettled([
        signContractPromise,
        updateCompanyPromise,
      ]);

      if (signContractResult.status === 'rejected') {
        return;
      }

      removeFromSS({ key: 'temporaryUserInfo' });
      removeFromSS({ key: 'temporaryCompanyInfos' });
      onSuccess?.();
    },
  });

  return {
    form,
    isLoading:
      isLoading ||
      isLoadingUpdateCompany ||
      isLoadingUpdateContractStatus ||
      isLoadingSignContract,
  };
};
