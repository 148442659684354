import styled from 'styled-components';
import { BaseSection } from '../../components';
import { Button as DSButton } from '@flash-tecnologia/hros-web-ui-v2';
import { Link } from 'react-router-dom';

const SectionImage =
  'https://images.flashapp.com.br/flash-os/signup/landing-page/faq_card.png';

export const Section = styled(BaseSection)`
  @media screen and (min-width: 1200px) {
    background-image: url(${SectionImage});
    background-repeat: no-repeat;
    background-position: right 140px bottom 50%;
  }

  @media screen and (max-width: 600px) {
    padding: 40px 24px;
  }
`;

export const SectionContent = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.s};
`;

export const TextContent = styled.div`
  @media screen and (max-width: 450px) {
    text-align: center;
  }
`;

export const Button = styled(DSButton)`
  @media screen and (max-width: 450px) {
    width: 100%;
  }
`;

export const IconContent = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs2};
`;

export const IconContentText = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const MoreInfoContainer = styled.div`
  width: 160px;
  height: 40px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #ffe0ef;
  border-radius: 60px;
  box-shadow: 0px 4px 28px 0px #fe2b8f1f;

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const HashLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.xs4};
`;
