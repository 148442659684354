import { useEffect, useState } from 'react';
import {
  LinkButton,
  Icons,
  Checkbox,
  SelectField,
  Typography,
  dayjs,
  ShapeIcon,
  CircularProgress,
} from '@flash-tecnologia/hros-web-ui-v2';

import { CardProducts } from './components/CardProducts';
import { Taxes } from './components/Taxes';
import { useParams, useSearchParams } from 'react-router-dom';
import { ContractErrorModal } from './components/ContractErrorModal';
import { trackEvent, trackPage } from '../../../../utils/eventTracking';
import { FormikProps } from 'formik';
import { useGetContractSignedUrl } from '../../hooks/use-get-contract-signed-url';
import { CommercialConditionsFormValues } from './commercial-conditions.hooks';
import { GeneralServicesTerm } from '@/components';
import { SignupType } from '../../signup.constants';
import { useGetCommercialConditions } from '../../hooks/use-get-commercial-conditions';
import { getFromSS } from '@flash-tecnologia/hros-web-utility';
import { useCreateContract } from '../../hooks/use-create-contract';
import {
  FieldsContainer,
  MainContainer,
  MessageContainer,
  RequiredText,
  StyledInput,
} from '../CompanyInfo/company-info.styles';
import {
  Card,
  CardContent,
  CardDescription,
  CardsContainer,
  CardTitle,
  ConfirmationContainer,
  LeftColumn,
  LoadingWrapper,
  RightColumn,
  TermText,
  TermTextLink,
} from './commercial-conditions.styles';
import { useGetCompanyContracts } from '../../hooks/use-get-company-contracts';

type ComercialConditionsProps = {
  form: FormikProps<CommercialConditionsFormValues>;
  signupType: SignupType;
};

export const ComercialConditions = ({
  form,
  signupType,
}: ComercialConditionsProps) => {
  const { dealId } = useParams();
  const [searchParams] = useSearchParams();
  const {
    getCommercialConditions,
    isLoading,
    data: commercialConditionsData,
  } = useGetCommercialConditions();
  const { getSignedUrl, isLoading: isLoadingContractUrl } =
    useGetContractSignedUrl();
  const { getCompanyContracts, isLoading: isLoadingGetContracts } =
    useGetCompanyContracts();
  const { createContract, isLoading: isLoadingCreateContract } =
    useCreateContract();

  const dealIdV2 = searchParams.get('dealId');
  const [contractId, setContractId] = useState(searchParams.get('contractId'));
  const [serviceTermOpen, setServiceTermOpen] = useState(false);
  const [contractUrl, setContractUrl] = useState('');
  const [showContractError, setShowContractError] = useState(false);

  const getContractSignedUrl = async (createdContractId?: string) => {
    if (isLoadingContractUrl) return;
    setShowContractError(false);

    try {
      const temporaryCompanyInfos = getFromSS('temporaryCompanyInfos');
      const shouldUseV2 = !!dealIdV2 || signupType === 'self';

      const contract = shouldUseV2
        ? await getSignedUrl({
            employeeId: temporaryCompanyInfos!.employeeId,
            contractId: createdContractId || contractId!,
          })
        : await getSignedUrl({ dealId: dealId! });

      setContractUrl(contract.url);
    } catch {
      setShowContractError(true);
    }
  };

  const openContract = () => {
    trackEvent('signup_contract_accept_open_contract_clicked');
    if (!contractUrl) getContractSignedUrl();
    setServiceTermOpen(true);
  };

  const fetchBenefitsContract = async () => {
    const temporaryCompanyInfos = getFromSS('temporaryCompanyInfos');
    const contracts = await getCompanyContracts({
      employeeId: temporaryCompanyInfos!.employeeId || '',
      companyId: temporaryCompanyInfos!.companyId || '',
    });
    const benefitContract = contracts.find(
      (contract) => contract.type === 'benefits',
    );
    if (benefitContract) {
      setContractId(benefitContract.id);
      getCommercialConditions({ contractId: benefitContract.id });
      form.setFieldValue('contractId', benefitContract.id);
      getContractSignedUrl(benefitContract.id);
    }
  };

  useEffect(() => {
    const createContractV2 = async () => {
      const temporaryCompanyInfos = getFromSS('temporaryCompanyInfos');

      const contract = await createContract({
        dealId: dealIdV2!,
        employeeId: temporaryCompanyInfos!.employeeId,
        companyId: temporaryCompanyInfos!.companyId,
        email: temporaryCompanyInfos!.employeeEmail,
      });

      if (!contract.id) return;

      setContractId(contract.id);
      form.setFieldValue('contractId', contract.id);
      getCommercialConditions({ contractId: contract.id });
      getContractSignedUrl(contract.id);
    };
    trackPage('signup_contract_accept_page');

    if (signupType === 'self' && !contractId) {
      createContractV2();
      return;
    }

    if (dealIdV2) {
      fetchBenefitsContract();
      return;
    }

    if (contractId) {
      getCommercialConditions({ contractId });
      form.setFieldValue('contractId', contractId);
    } else if (dealId) {
      getCommercialConditions({ dealId });
    }

    getContractSignedUrl();
  }, []);

  useEffect(() => {
    if (dealIdV2 && !isLoadingGetContracts && !contractId) {
      fetchBenefitsContract();
    }
  }, [isLoadingGetContracts, dealIdV2, contractId]);

  if (isLoading || isLoadingCreateContract || !contractId) {
    return (
      <LoadingWrapper>
        <CircularProgress variant="indeterminate" size={80} />
      </LoadingWrapper>
    );
  }

  if (!commercialConditionsData) return null;

  const contractEndDate = dayjs()
    .add(Number(commercialConditionsData?.contractTermInMonths), 'months')
    .format('DD.MM.YYYY');

  return (
    <MainContainer>
      <MessageContainer>
        <Typography
          variant="body3"
          variantColor="var(--color-primary)"
          // TODO: weight={700} is not overriding font-weight from variant
          style={{ fontWeight: 700 }}
          children="Crie o seu perfil na empresa"
        />
        <Typography variant="headline5" style={{ maxWidth: '412px' }}>
          Pronto! Agora é só aceitar seu contrato com{' '}
          <Typography
            variant="headline5"
            variantColor="var(--color-secondary-50)"
            tag="span"
          >
            taxas especiais
          </Typography>
        </Typography>
        <Typography
          variant="body3"
          variantColor="var(--color-neutral-50)"
          children="Para finalizar sua contratação, leia e aceite as condições comerciais do Contrato de Prestação de Serviços da Flash."
          style={{ maxWidth: '450px' }}
        />
      </MessageContainer>
      <MessageContainer>
        <Typography variant="headline8">
          Resumo das condições comerciais
        </Typography>
        <Typography variant="body3" variantColor="var(--color-neutral-50)">
          Condições válidas até {contractEndDate}.
          <br />
          Vigência do contrato de{' '}
          {commercialConditionsData?.contractTermInMonths} meses.
        </Typography>
        <LinkButton
          variant="secondary"
          onClick={openContract}
          style={{ alignSelf: 'flex-start' }}
        >
          <Icons
            name="IconNotes"
            fill="none"
            color="var(--color-neutral-50)"
            size={16}
          />{' '}
          Ver contrato completo
        </LinkButton>
      </MessageContainer>
      <CardsContainer>
        <Card>
          <LeftColumn>
            <ShapeIcon
              name="IconBox"
              variant="default"
              color="var(--color-secondary-50)"
              size={40}
            />
          </LeftColumn>
          <RightColumn>
            <CardContent>
              <CardTitle>Produtos do pacote de contratação</CardTitle>
              <CardDescription>
                Você poderá personalizar seus produtos posteriormente
              </CardDescription>
              <CardProducts products={commercialConditionsData.products} />
            </CardContent>
          </RightColumn>
        </Card>
        <Card>
          <LeftColumn>
            <ShapeIcon
              name="IconReceipt2"
              variant="default"
              color="var(--color-secondary-50)"
              size={40}
            />
          </LeftColumn>
          <RightColumn>
            <CardContent>
              <CardTitle>Tarifa dos produtos</CardTitle>
              <Taxes taxes={commercialConditionsData.taxes} />
            </CardContent>
          </RightColumn>
        </Card>
      </CardsContainer>
      <FieldsContainer>
        <RequiredText variant="body3" variantColor="var(--color-neutral-30)">
          <Typography
            variant="body3"
            tag="span"
            variantColor="var(--color-feedback-error-50)"
          >
            *
          </Typography>{' '}
          campo obrigatório
        </RequiredText>
        <SelectField
          required
          id="isPatRegistered"
          name="isPatRegistered"
          label="Empresa inscrita no PAT? *"
          onSelectChange={(_, option) =>
            form.setFieldValue('isPatRegistered', option.value, true)
          }
          value={form.values.isPatRegistered}
          options={[
            {
              label: 'Não, declaro que minha empresa não é registrada no PAT.',
              value: false,
            },
            {
              label: 'Sim, declaro que minha empresa é registrada no PAT.',
              value: true,
            },
          ]}
        />
        {form.values.isPatRegistered && (
          <StyledInput
            required
            fullWidth
            id="patInscriptionNumber"
            name="patInscriptionNumber"
            label="Número de inscrição no PAT"
            value={form.values.patInscriptionNumber}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            error={
              form.touched.patInscriptionNumber &&
              Boolean(form.errors.patInscriptionNumber)
            }
            helperText={form.errors.patInscriptionNumber}
          />
        )}
        <ConfirmationContainer>
          <Checkbox
            name="contractCheck"
            value={form.values.contractCheck}
            onChange={(e) =>
              form.setFieldValue('contractCheck', e.target.checked, true)
            }
          />
          <TermText>
            Li e aceito o{' '}
            <TermTextLink
              onClick={openContract}
              children="contrato de prestação de serviços"
            />{' '}
            da Flash
          </TermText>
          <GeneralServicesTerm
            open={serviceTermOpen && !showContractError}
            onClose={() => setServiceTermOpen(false)}
            loading={isLoadingContractUrl}
            url={contractUrl}
            onRetry={getContractSignedUrl}
          />
          <ContractErrorModal
            open={serviceTermOpen && showContractError}
            onClose={() => setServiceTermOpen(false)}
          />
        </ConfirmationContainer>
      </FieldsContainer>
    </MainContainer>
  );
};
