import styled from 'styled-components';
import { BaseSection } from '../../components';
import { Button as DSButton } from '@flash-tecnologia/hros-web-ui-v2';
import { Swiper as BaseSwiper } from 'swiper/react';

export const Section = styled(BaseSection)`
  @media screen and (max-width: 600px) {
    padding: 40px 24px;
  }
`;

export const SectionContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.s};
`;

export const TextContent = styled.div`
  @media screen and (max-width: 450px) {
    text-align: center;
  }
`;

export const Swiper = styled(BaseSwiper)`
  width: 100%;
`;

export const NavigationButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Button = styled(DSButton)`
  width: 240px;
  align-self: auto;

  @media screen and (max-width: 450px) {
    width: 100%;
  }
`;

export const StepsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.spacings.m};

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const StepContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
  padding: 32px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 8px;
`;
