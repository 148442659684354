import { OTPField, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

const MainContainer = styled.form`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
const OTPContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  gap: 10px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    gap: 5px;
  }
`;

const ButtonsArea = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 350px) {
    flex-direction: column-reverse;
    gap: 15px;
  }
`;

const OptionsContainer = styled.div`
  margin: 40px 0px;
  display: flex;
  flex-direction: column;
`;
const OptionsField = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 5px;

  @media screen and (min-width: 781px) and (max-width: 860px) {
    flex-direction: column;
    align-items: start;
    margin-top: 15px;
  }
  @media screen and (max-width: 520px) {
    flex-direction: column;
    align-items: start;
    margin-top: 15px;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
`;

const StyledOTP = styled(OTPField)``;

const AwaitContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
const AwaitText = styled(Typography)`
  text-align: center;
  font-weight: 400;
  color: #83727d;

  @media screen and (max-width: 525px) {
    text-align: left;
  }
`;

export {
  MainContainer,
  OTPContainer,
  ButtonsArea,
  OptionsContainer,
  OptionsField,
  MessageContainer,
  StyledOTP,
  AwaitContainer,
  AwaitText,
};
