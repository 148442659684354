import { QueryClient } from '@tanstack/react-query';
import { createTRPCReact, httpLink } from '@trpc/react-query';
import type { AppRouter } from 'bff';
import { Auth } from '@flash-tecnologia/hros-web-utility';

export const trpc = createTRPCReact<AppRouter>();

export const queryClient = new QueryClient();
export const trpcClient = trpc.createClient({
  links: [
    httpLink({
      url: `${process.env.BFF_URL}/trpc`,
      headers: async ({ op }) => {
        if (op.context.useCognitoToken) {
          try {
            const currentSession = await Auth.currentSession();
            const cognitoToken = currentSession.getIdToken().getJwtToken();
            return { Authorization: `Bearer ${cognitoToken}` };
          } catch {
            return {};
          }
        }

        return {};
      },
    }),
  ],
});
