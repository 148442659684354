import { Icons, ShapeIcon, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import * as SC from './styles';

type VideoShowcaseSectionProps = {
  onCtaClick: () => void;
};

export const VideoShowcaseSection = ({
  onCtaClick,
}: VideoShowcaseSectionProps) => {
  return (
    <SC.Background>
      <SC.Section>
        <SC.CtaContainer>
          <SC.TextContent>
            <Typography
              variant="headline6"
              variantColor="var(--color-neutral-100)"
            >
              A Flash tem o produto certo para sua empresa
            </Typography>
            <Typography variant="body3" variantColor="var(--color-neutral-100)">
              Tenha acesso a melhor plataforma de gestão de benefícios e
              despesas!
            </Typography>
          </SC.TextContent>
          <SC.Button
            size="large"
            variant="primary"
            style={{ background: '#3B2E37' }}
            onClick={onCtaClick}
          >
            Cadastrar gratuitamente
          </SC.Button>
        </SC.CtaContainer>
        <SC.VideoContainer>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube-nocookie.com/embed/xAKZycKFI94?si=Z1yJSi27RWrXncvP"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </SC.VideoContainer>
      </SC.Section>
    </SC.Background>
  );
};
