import { dispatchToast } from '../../../utils';
import { trpc } from '@api/client';

export const useUpdateCompany = () => {
  const { mutateAsync, isLoading } = trpc.updateCompany.useMutation({
    trpc: { context: { useCognitoToken: true } },
  });

  return { updateCompany: mutateAsync, isLoading };
};
