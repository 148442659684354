import styled from 'styled-components';
interface FrontBarProps {
  steps: number;
  current: number;
}

const LineContainer = styled.div``;
const BackBar = styled.div`
  height: 3px;
  background-color: #e0e0de;
  border-radius: 50px;
  margin: 0px;
`;
const FrontBar = styled.div<FrontBarProps>`
  height: 100%;
  background-color: #fe2b8f;
  border-radius: 50px;
  transition: width 1s ease-in-out;
  ${(props) => {
    const { steps, current } = props;
    return {
      width: `${(100 * current) / steps}%`,
    };
  }}
`;

export { LineContainer, BackBar, FrontBar };
