import { getFromSS, setInSS } from '@flash-tecnologia/hros-web-utility';
import { ErrorBoundary, trackEvent, validateEmail } from '@utils';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useCompanySignupMutation } from '../../hooks/use-company-signup';
import { useSearchParams } from 'react-router-dom';
import { useCompanySelfSignupMutation } from '../../hooks/use-company-self-signup';
import { useUpdateDealStage } from '../../hooks/use-update-deal-stage';

const validationSchema = yup.object({
  name: yup.string().required('Por favor, digite o nome do administrador'),
  phone: yup
    .string()
    .min(16, 'O número deve conter 11 dígitos')
    .required('Por favor, digite o número de celular do administrador'),
  email: yup.string().required('Por favor, digite o e-mail do administrador'),
});

export type AdminInfoFormValues = {
  email: string;
  name: string;
  documentNumber: string;
  phone: string;
};

const initialValues: AdminInfoFormValues = {
  email: '',
  name: '',
  documentNumber: '',
  phone: '',
};

type AdminInfoFormProps = {
  signupType?: string;
  dealId?: string;
  leadId?: string;
  onSuccess?: (contractId?: string) => void;
};

export const useAdminInfoForm = ({
  onSuccess,
  signupType,
  dealId,
  leadId,
}: AdminInfoFormProps) => {
  const { companySignUp, isLoading: isSignupLoading } =
    useCompanySignupMutation();
  const { companySelfSignUp, isLoading: isSelfLoading } =
    useCompanySelfSignupMutation();
  const { updateDealStage } = useUpdateDealStage();
  const [searchParams] = useSearchParams();
  const selfSignupToken = searchParams.get('self_signup_token');

  const form = useFormik({
    initialValues,
    validationSchema,
    isInitialValid: false,
    validate: (values) => {
      let errors: any = {};
      if (values.email && !validateEmail(values.email))
        errors.email = 'Por favor, digite um e-mail válido.';
      return errors;
    },
    onSubmit: async (values) => {
      trackEvent('signup_admin_info_continue_clicked');
      try {
        const temporaryCompanyInfos = getFromSS('temporaryCompanyInfos');
        const employeeInfo = {
          email: values.email,
          phone: values.phone,
          name: values.name,
          documentNumber: values.documentNumber,
        };
        const companyInfo = {
          registrationNumber: temporaryCompanyInfos?.registrationNumber,
          legalName: temporaryCompanyInfos?.legalName,
          name: temporaryCompanyInfos?.name,
          address: {
            zipCode: temporaryCompanyInfos?.address?.zipCode,
            street: temporaryCompanyInfos?.address?.street,
            number: temporaryCompanyInfos?.address?.number,
            complement: temporaryCompanyInfos?.address?.complement || '',
            district: temporaryCompanyInfos?.address?.district,
            city: temporaryCompanyInfos?.address?.city,
            state: temporaryCompanyInfos?.address?.state,
          },
        };

        let companyId: string;
        let employeeId: string;
        let contractId: string | undefined;
        if (signupType === 'self') {
          const result = await companySelfSignUp({
            employeeInfo,
            companyInfo: {
              ...companyInfo,
              employeesQuantity: temporaryCompanyInfos?.employeesQuantity,
            },
            selfSignupToken,
          });

          companyId = result!.company.value.companyId;
          employeeId = result!.company.value.employeeId;
          contractId = result!.contractIds[0];
        } else {
          const result = await companySignUp({
            employeeInfo,
            companyInfo,
            dealId,
            leadId,
          });

          employeeId = result!.value.employeeId;
          companyId = result!.value.companyId;
        }

        setInSS({
          key: 'temporaryCompanyInfos',
          value: {
            ...temporaryCompanyInfos,
            employeeEmail: employeeInfo.email,
            employeeId,
            companyId,
            contractId,
          },
        });

        if (selfSignupToken) {
          updateDealStage({
            trackingId: selfSignupToken,
            stage: 'signup-success',
          });
        }
        onSuccess?.(contractId);
      } catch (error) {
        ErrorBoundary.captureException(error as Error);
      }
    },
  });

  return { form, isLoading: isSelfLoading || isSignupLoading };
};
