import * as SC from './styles';

type BannerSectionProps = {
  headline: string;
};

export const BannerSection = ({ headline }: BannerSectionProps) => {
  return (
    <SC.Section>
      <SC.Banner>
        <SC.Typography
          variant="headline9"
          variantColor="var(--color-neutral-100)"
        >
          Continue para experimentar grátis
        </SC.Typography>
        <SC.Typography
          variant="headline6"
          variantColor="var(--color-neutral-100)"
        >
          {headline}
        </SC.Typography>
        <SC.Typography
          variant="body3"
          variantColor="var(--color-neutral-100)"
          style={{ maxWidth: '380px' }}
        >
          Complete seu cadastro nos próximos passos para começar a usar nossa
          plataforma hoje mesmo.
        </SC.Typography>
      </SC.Banner>
    </SC.Section>
  );
};
