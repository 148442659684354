import styled from 'styled-components';

export const Section = styled.div`
  width: 100%;
  max-width: 1366px;
  padding: 60px 140px;

  @media screen and (max-width: 600px) {
    padding: 40px 24px;
  }
`;
