import { trpc } from '@api/client';
import { dispatchToast } from '@utils';

export const useGetSalesCompany = () => {
  const { mutateAsync, isLoading } = trpc.getSalesCompany.useMutation({
    onError: () => {
      dispatchToast({
        type: 'error',
        content:
          'Não foi possível obter os dados da sua empresa. Por favor, tente novamente.',
      });
    },
  });

  return { getSalesCompany: mutateAsync, isLoading };
};
