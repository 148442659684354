import styled from 'styled-components';
import { BaseSection } from '../../components';
import { Button as DSButton } from '@flash-tecnologia/hros-web-ui-v2';

export const Background = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.colors.secondary[50]};
`;

export const Section = styled(BaseSection)`
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.spacings.l};

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }

  @media screen and (max-width: 450px) {
    padding: 0;
    gap: ${({ theme }) => theme.spacings.m};
  }
`;

export const CtaContainer = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.l};

  @media screen and (max-width: 800px) {
    width: 100%;
  }

  @media screen and (max-width: 450px) {
    padding: 40px 24px 0;
    gap: ${({ theme }) => theme.spacings.xs};
  }
`;

export const Button = styled(DSButton)`
  width: 240px;
  align-self: auto;

  @media screen and (max-width: 450px) {
    width: 100%;
  }
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};

  @media screen and (max-width: 450px) {
    text-align: center;
  }
`;

export const VideoContainer = styled.div`
  width: 100%;
  max-width: 600px;
  height: 390px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`;
