import { Icons, ShapeIcon, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import * as SC from './styles';
import { useSearchParams } from 'react-router-dom';

type AboutProductSectionProps = {
  onCtaClick: () => void;
};

export const AboutProductSection = ({
  onCtaClick,
}: AboutProductSectionProps) => {
  const [searchParams] = useSearchParams();

  return (
    <SC.Section>
      <SC.SectionContent>
        <SC.TextContent>
          <Typography variant="headline6">O que poderei acessar?</Typography>
          <Typography variant="body3" variantColor="var(--color-neutral-30)">
            Ao concluir seu cadastro, você terá acesso à nossa plataforma com os
            produtos:
          </Typography>
        </SC.TextContent>
        <SC.IconContent>
          <ShapeIcon
            size={48}
            name="IconCreditCard"
            variant="default"
            color="var(--color-secondary-50)"
            style={{ flexShrink: 0 }}
          />
          <SC.IconContentText>
            <Typography
              variant="body2"
              variantColor="var(--color-neutral-30)"
              style={{ fontWeight: 700 }}
            >
              Cartão Flash Benefícios
            </Typography>
            <Typography variant="body3" variantColor="var(--color-neutral-30)">
              Um só cartão com multibenefícios, alimentação e refeição, saúde,
              vale transporte, mobilidade, educação, home office, cultura,
              premiação e mais!
            </Typography>
          </SC.IconContentText>
        </SC.IconContent>
        <SC.IconContent>
          <ShapeIcon
            size={48}
            name="IconReceipt2"
            variant="default"
            color="var(--color-secondary-50)"
            style={{ flexShrink: 0 }}
          />
          <SC.IconContentText>
            <Typography
              variant="body2"
              variantColor="var(--color-neutral-30)"
              style={{ fontWeight: 700 }}
            >
              Cartão Corporativo
            </Typography>
            <Typography variant="body3" variantColor="var(--color-neutral-30)">
              Para empresas que querem simplificar a gestão de cartões
              corporativos, viagens e reembolso, tudo num mesmo cartão Flash.
            </Typography>
          </SC.IconContentText>
        </SC.IconContent>
        <SC.Button size="large" variant="primary" onClick={onCtaClick}>
          Cadastrar gratuitamente
        </SC.Button>
      </SC.SectionContent>
      <SC.MoreInfoContainer>
        <SC.HashLink to={`?${searchParams.toString()}#signup_steps_section`}>
          <Icons
            size={16}
            name="IconChevronsDown"
            color="var(--color-secondary-50)"
          />
          <Typography
            variant="body4"
            weight={600}
            variantColor="var(--color-secondary-50)"
          >
            Mais informações
          </Typography>
        </SC.HashLink>
      </SC.MoreInfoContainer>
    </SC.Section>
  );
};
