import { Icons, TextField, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const MainContainer = styled.form`
  width: 100%;
  max-width: 535px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const ButtonsArea = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 350px) {
    flex-direction: column-reverse;
    gap: 15px;
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HelperTextContainer = styled.div`
  display: flex;
  gap: 5px;

  align-items: center;
  padding-left: 18px;
`;
export const ErrorText = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.feedback.error.pure};
    font-size: 12px;
    font-weight: 700;
  }
`;

export const PasswordErrorText = styled(Typography)<{ hasError?: boolean }>`
  && {
    color: ${({ hasError, theme }) =>
      hasError ? theme.colors.secondary : theme.colors.feedback.success.dark1};
    font-size: 12px;
    font-weight: ${({ hasError }) => (hasError ? '400' : '700')};
  }
`;

export const PasswordErrorIcon = styled(Icons)<{ hasError?: boolean }>`
  && {
    color: ${({ hasError, theme }) =>
      hasError ? theme.colors.secondary : theme.colors.feedback.success.dark1};
  }
`;

export const StyledIcon = styled(Icons)`
  && {
    color: ${({ theme }) => theme.colors.feedback.error.pure};
  }
`;

export const StyledInput = styled(TextField)`
  && {
    margin-top: 24px;
  }
`;
