import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ConfigurationContext } from '../context';
import {
  ContractPreview,
  Deal,
  Sales,
  SelfSignupLandingPage,
  SignUp,
} from '@pages';

const AppRouter = () => {
  return (
    <ConfigurationContext>
      <BrowserRouter>
        <Routes>
          <Route path="/signup/lp/self" element={<SelfSignupLandingPage />} />

          <Route path="/signup/self/:step">
            <Route index element={<SignUp signupType="self" />} />
            <Route
              path=":invitationCode/:companyId"
              element={<SignUp signupType="self" />}
            />
          </Route>

          <Route path="/signup/trial/:leadId/:step">
            <Route index element={<SignUp signupType="trial" />} />
            <Route
              path=":invitationCode/:companyId"
              element={<SignUp signupType="trial" />}
            />
          </Route>

          <Route path="/signup/sales/custom/:hash" element={<Sales />} />
          <Route path="/signup/sales/:step/:companyId/:contractId">
            <Route index element={<SignUp signupType="sales" />} />
            <Route
              path=":invitationCode"
              element={<SignUp signupType="sales" />}
            />
          </Route>

          <Route path="/signup/hubspot/:dealId" element={<Deal />} />
          <Route path="/signup/hubspot/:dealId/:step">
            <Route index element={<SignUp signupType="hubspot" />} />
            <Route
              path=":invitationCode/:companyId"
              element={<SignUp signupType="hubspot" />}
            />
          </Route>

          <Route
            path="/signup/contract-preview/:product"
            element={<ContractPreview />}
          />

          <Route
            path="/signup/*"
            element={<Navigate to={'/signup/self/create-company'} replace />}
          />
        </Routes>
      </BrowserRouter>
    </ConfigurationContext>
  );
};

export default AppRouter;
