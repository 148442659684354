import { trpc } from '@api/client';
import { dispatchToast } from '@utils';

export const useActiveCompany = () => {
  const { mutateAsync, isLoading } = trpc.activateCompany.useMutation({
    onError: () => {
      dispatchToast({
        type: 'error',
        content:
          'Ocorreu um erro ao ativar sua empresa. Por favor, tente novamente.',
      });
    },
  });

  return { activateCompany: mutateAsync, isLoading };
};
