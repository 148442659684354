import {
  IconButton,
  Icons,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';

import { CloseContainer, StyledModal } from './styles';

interface ModalProps {
  open: boolean;
  onClose: () => void;
}

// TODO: Use Modal from design system after updating it
export const ContractErrorModal = ({ open, onClose }: ModalProps) => {
  return (
    <StyledModal
      open={open}
      onClose={onClose}
      header={
        <CloseContainer>
          <IconButton
            icon="IconX"
            onClick={onClose}
            variant="line"
            size="small"
          />
        </CloseContainer>
      }
    >
      <div style={{ maxWidth: '420px', textAlign: 'center' }}>
        <div
          // TODO:L Replace for ShapeIcon of design system
          style={{
            margin: '0 auto 24px',
            textAlign: 'center',
            background: 'var(--color-feedback-error-90)',
            borderRadius: '100%',
            width: 64,
            height: 64,
            padding: 8,
          }}
        >
          <Icons
            name="IconAlertCircle"
            size={48}
            color="var(--color-feedback-error-40)"
            fill="none"
          />
        </div>
        <Typography
          variant="body3"
          style={{ color: 'var(--color-feedback-error-40', fontWeight: 700 }}
        >
          Atenção!
        </Typography>
        <Typography variant="headline6" style={{ marginBottom: 8 }}>
          Não foi possível gerar o seu contrato
        </Typography>
        <Typography
          variant="body3"
          style={{ color: 'var(--color-neutral-50)' }}
        >
          Por favor entre, em contato com o suporte pelo e-mail
          falecom@flashapp.com.br ou pelo telefone <br />
          (11) 4118-0488
        </Typography>
      </div>
    </StyledModal>
  );
};
