import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormikProps } from 'formik';
import { TextField, Typography } from '@flash-tecnologia/hros-web-ui-v2';

import { ErrorText, StyledIcon, StyledInput } from './admin-info.styles';

import {
  FieldsContainer,
  HelperTextContainer,
  MainContainer,
  MessageContainer,
  RequiredText,
} from '../CompanyInfo/company-info.styles';
import { AdminInfoFormValues } from './admin-info.hooks';
import { trackPage } from '@/utils';
import { getFromSS } from '@flash-tecnologia/hros-web-utility';

type AdminInfoProps = {
  form: FormikProps<AdminInfoFormValues>;
  isSales;
  companyId;
  contractId;
  dealId;
  leadId;
  authenticatedUser;
  loading;
};

export const AdminInfo = ({
  form,
  isSales,
  companyId,
  contractId,
  dealId,
  leadId,
  authenticatedUser,
  loading,
}: AdminInfoProps) => {
  const navigate = useNavigate();
  const searchParams = useSearchParams();

  useEffect(() => {
    trackPage('signup_admin_info_page');

    const temporaryCompanyInfos = getFromSS('temporaryCompanyInfos');
    if (!temporaryCompanyInfos) {
      if (dealId !== undefined) {
        navigate(
          `/signup/hubspot/${dealId}/create-company?${searchParams.toString()}`,
        );
        return;
      }

      navigate('/signup/self/create-company');
    }
  }, []);

  useEffect(() => {
    if (loading) return;
    //same behavior as companyInfo page
    if (!authenticatedUser) {
      switch (true) {
        case isSales:
          navigate(`/signup/sales/create-admin/${companyId}/${contractId}`);
          break;
        case dealId !== undefined:
          navigate(
            `/signup/hubspot/${dealId}/create-admin?${searchParams.toString()}`,
          );
          break;
        case leadId !== undefined:
          navigate(`/signup/trial/${leadId}/create-admin`);
          break;
        default:
          navigate('/signup/self/create-admin');
          break;
      }
    }
  }, [authenticatedUser, loading]);

  return (
    <MainContainer>
      <MessageContainer>
        <Typography
          variant="body3"
          variantColor="var(--color-primary)"
          // TODO: weight={700} is not overriding font-weight from variant
          style={{ fontWeight: 700 }}
          children="Crie o seu perfil na empresa"
        />
        <Typography
          variant="headline5"
          children="Informe os seus dados de administrador na empresa"
        />

        <Typography
          variant="body3"
          variantColor="var(--color-neutral-50)"
          children="Esses dados serão utilizados unicamente para a visualização das pessoas que trabalham em sua empresa. Inclua os dados de contato que você utiliza para fins de trabalho."
        />
      </MessageContainer>
      {!loading && (
        <FieldsContainer>
          <RequiredText variant="body3" variantColor="var(--color-neutral-30)">
            <Typography
              variant="body3"
              tag="span"
              variantColor="var(--color-feedback-error-50)"
            >
              *
            </Typography>{' '}
            campo obrigatório
          </RequiredText>
          <TextField
            required
            label="Nome Completo"
            id="name"
            name="name"
            value={form.values.name}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            error={form.touched.name && Boolean(form.errors.name)}
          />
          {form.touched.name && Boolean(form.errors.name) && (
            <HelperTextContainer>
              <StyledIcon size={15} name="IconAlertCircle" fill="none" />
              <ErrorText variant="body4">{form.errors.name}</ErrorText>
            </HelperTextContainer>
          )}

          <StyledInput
            required
            label="E-mail empresarial"
            id="email"
            name="email"
            value={form.values.email}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            error={form.touched.email && Boolean(form.errors.email)}
          />
          <HelperTextContainer>
            <Typography
              variant="caption"
              variantColor="var(--color-neutral-50)"
              weight={600}
            >
              Caso não possua um e-mail empresarial, preencha com um pessoal.
            </Typography>
          </HelperTextContainer>
          {form.touched.email && Boolean(form.errors.email) && (
            <HelperTextContainer>
              <StyledIcon size={15} name="IconAlertCircle" fill="none" />
              <ErrorText variant="body4">{form.errors.email}</ErrorText>
            </HelperTextContainer>
          )}

          <StyledInput
            required
            label="Celular empresarial"
            id="phone"
            name="phone"
            value={form.values.phone}
            imaskProps={{ mask: '(00) 0 0000-0000' }}
            onChange={(e) => {
              // For some unknown issue, when using imaskProps
              // the formik is receiving the values as the initialState
              // which is messing with the validation and the other fields
              // Workaround:
              form.setValues({ ...form.values, phone: e.target.value }, true);
            }}
            onBlur={form.handleBlur}
            error={form.touched.phone && Boolean(form.errors.phone)}
          />
          <HelperTextContainer>
            <Typography
              variant="caption"
              variantColor="var(--color-neutral-50)"
              weight={600}
            >
              Caso não possua um celular empresarial, preencha com um número
              pessoal.
            </Typography>
          </HelperTextContainer>
          {form.touched.phone && Boolean(form.errors.phone) && (
            <HelperTextContainer>
              <StyledIcon size={15} name="IconAlertCircle" fill="none" />
              <ErrorText variant="body4">{form.errors.phone}</ErrorText>
            </HelperTextContainer>
          )}
        </FieldsContainer>
      )}
    </MainContainer>
  );
};
