import { PageContainer } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

const footerHeight = 80;

export const Header = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
  padding: ${({ theme }) => `${theme.spacings.xs2} ${theme.spacings.xs}`};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ContentContainer = styled.div`
  // max-width: 1366px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding-bottom: ${footerHeight}px;
`;

export const ImageContainer = styled.div<{ imageUrl: string }>`
  background-image: url(${({ imageUrl }) => imageUrl});
  width: 420px;
  height: 565px;
  min-width: 420px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 40px;
  margin-top: ${({ theme }) => theme.spacings.s};
  cursor: pointer;

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const TopicsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
  padding-bottom: ${({ theme }) => theme.spacings.s};

  @media screen and (min-width: 700px) {
    height: 100%;

    & > div {
      height: 100%;
    }
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs4};
  margin-bottom: ${({ theme }) => theme.spacings.s};
  padding-top: ${({ theme }) => theme.spacings.s};
`;

export const Footer = styled.div`
  height: ${footerHeight}px;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #fff;
  z-index: 1;
  gap: ${({ theme }) => theme.spacings.xs};
  padding: ${({ theme }) => `${theme.spacings.xs2} ${theme.spacings.xs}`};
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;
