import {
  LinkButton,
  Modal,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';

import { FlexBetween, StyledPDFViewer } from './styles';
import { useEffect, useState } from 'react';
// import { getS3Presigned } from '@flash-tecnologia/hros-web-utility';

interface ModalProps {
  open: boolean;
  onClose: () => any;
}

export const TermsAndConditionsModal = ({ open, onClose }: ModalProps) => {
  const [signedUrl, setSignedUrl] = useState(
    'https://drive.google.com/file/d/1CPRw0q8lXMqDIIIVrJheoOvouXzPt1vB/preview',
  );

  // useEffect(() => {
  //   const getTermsUrl = async () => {
  //     const { url = '' } = await getS3Presigned({
  //       filename: 'pdfs/common/TERMOS_DE_USO_VF_20240503.pdf',
  //       module: 'contract-manager',
  //     });
  //     setSignedUrl(url);
  //   };

  //   getTermsUrl();
  // }, []);

  return (
    <Modal.Root
      open={open}
      showClose
      onClose={onClose}
      style={{ overflow: 'auto' }}
    >
      <>
        <Modal.Header
          title="Termos e Condições Gerais de Uso"
          description="Para usar nossos serviços, você deve ler atentamente e aceitar aos
            Termos e Condições Gerais de Uso da Flash."
        />
        <Modal.Content>
          <Typography
            variant="body4"
            style={{ marginBottom: '8px', fontWeight: 'bold' }}
          >
            Leia abaixo
          </Typography>
          <StyledPDFViewer
            width="100%"
            height="400px"
            src={signedUrl}
            options={{ showToolbar: true, fit: 'height', page: 1 }}
          />
        </Modal.Content>
        <Modal.Footer onConfirm={onClose}>
          <FlexBetween>
            <LinkButton
              variant="primary"
              onClick={onClose}
              style={{ alignSelf: 'center' }}
            >
              Fechar
            </LinkButton>
          </FlexBetween>
        </Modal.Footer>
      </>
    </Modal.Root>
  );
};
