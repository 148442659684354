import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const CardItens = styled.div`
  margin-bottom: 20px;
`;

export const CardDescriptionTitle = styled(Typography).attrs({
  variant: 'body4',
  style: { fontWeight: 'Bold' },
})`
  color: ${({ theme }) => theme.colors.neutral[40]};
  margin-bottom: 4px;
`;

export const CardDescription = styled(Typography).attrs({ variant: 'body4' })`
  color: ${({ theme }) => theme.colors.neutral[50]};
  margin-bottom: 16px;
`;
