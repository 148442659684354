import { trpc } from '@api/client';

export const useGetContractSignedUrl = () => {
  const getContractSignedUrl = trpc.getContractSignedUrl.useMutation({
    trpc: { context: { useCognitoToken: true } },
  });

  const getContractTemplateByDealId =
    trpc.getContractTemplateByDealId.useMutation({
      trpc: { context: { useCognitoToken: true } },
    });

  const getSignedUrl = async (
    input: { dealId: string } | { employeeId: string; contractId: string },
  ) => {
    if ('contractId' in input) {
      return getContractSignedUrl.mutateAsync({
        employeeId: input.employeeId,
        contractId: input.contractId!,
      });
    }

    return getContractTemplateByDealId.mutateAsync({
      dealId: input.dealId!,
    });
  };

  return {
    getSignedUrl,
    isLoading:
      getContractSignedUrl.isLoading || getContractTemplateByDealId.isLoading,
  };
};
