import { parseFeatureFlagPayload } from '@/utils/parse-feature-flag-payload';
import { useFlag } from '@flash-tecnologia/feature-flags';

type FeatureFlagPayload = {
  banner: {
    headline: string;
  };
  videoSection: {
    title: string;
    description: string;
  };
};

type LpVariant = {
  name: string;
  payload: FeatureFlagPayload;
};

export const useGetLpVariant = (): LpVariant => {
  const { name, payload } = useFlag({
    flagName: 'FLASH_OS_SELF_SIGNUP_LANDING_PAGE',
    variant: true,
  });

  if (payload?.value) {
    const featureFlagPayload = parseFeatureFlagPayload<FeatureFlagPayload>(
      payload.value,
    );

    if (featureFlagPayload) {
      return {
        name,
        payload: featureFlagPayload!,
      };
    }
  }

  return {
    name: 'sales',
    payload: {
      banner: {
        headline: 'Flash sem custos e com taxa zero!',
      },
      videoSection: {
        title: 'A Flash tem o produto certo para sua empresa',
        description:
          'Tenha acesso a melhor plataforma de gestão de benefícios e despesas!',
      },
    },
  };
};
