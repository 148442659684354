import { Icons } from '@flash-tecnologia/hros-web-ui-v2';

import {
  FlashContacts,
  FlashPhone,
  FlashInfoContainer,
  StyledText,
  PhoneText,
} from './styled';

export const PageFooter = () => {
  return (
    <FlashInfoContainer>
      <div>
        <StyledText
          variant="body4"
          children={`Flash Tecnologia e Pagamentos LTDA `}
        />
        <StyledText
          variant="body4"
          children={`CNPJ 32.223.020/0001-18 • Registrado no PAT nº 190679531`}
        />
      </div>
      <div>
        <StyledText
          variant="body4"
          children={`Duvidas? Fale com nosso time de vendas!`}
        />
        <FlashContacts>
          <FlashPhone>
            <Icons name={'IconHeadset'} color={'#ED718B'} fill="none" />
            <PhoneText
              variant="body4"
              children={`(11) 4118-0488`}
              color={'#ed718b'}
            />
          </FlashPhone>
          <FlashPhone>
            <Icons name={'IconMail'} color={'#ED718B'} fill="none" />
            <PhoneText
              variant="body4"
              children={`empresa@flashapp.com.br`}
              color={'#ed718b'}
            />
          </FlashPhone>
        </FlashContacts>
      </div>
    </FlashInfoContainer>
  );
};
