import styled from 'styled-components';
import { PDFViewer } from '@flash-tecnologia/hros-web-ui-v2';

export const StyledPDFViewer = styled(PDFViewer)`
  @media screen and (max-height: 750px) {
    height: 250px;
  }
`;

export const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
