import { setInSS, removeFromSS } from '@flash-hros/utility';
import { dispatchToast, formatCompanySignupPhone } from '../../../utils';
import { trpc } from '@api/client';

export const useCompanySignupMutation = () => {
  const { mutateAsync, isLoading } = trpc.companySignup.useMutation({
    trpc: { context: { useCognitoToken: true } },
    onError: () => {
      dispatchToast({
        type: 'error',
        content:
          'Ops! Não foi possível cadastrar sua empresa no momento. Tente novamente mais tarde ou fale com nosso suporte.',
      });
    },
  });

  const companySignUp = async ({
    employeeInfo,
    companyInfo,
    dealId,
    leadId,
  }) => {
    if (isLoading) return;

    const documentNumber = employeeInfo.documentNumber.replace(/\D/g, '');
    removeFromSS({ key: 'temporaryUserInfo' });
    setInSS({
      key: 'temporaryUserInfo',
      value: {
        email: employeeInfo.email,
        phone_number: employeeInfo.phone,
        name: employeeInfo.name,
        documentNumber,
      },
    });

    const phone = formatCompanySignupPhone(employeeInfo.phone!);
    const response = await mutateAsync({
      employee: {
        name: employeeInfo.name,
        documentNumber: documentNumber,
        email: employeeInfo.email,
        phone,
      },
      company: companyInfo,
      dealId,
      leadId,
    });
    return response;
  };

  return { companySignUp, isLoading };
};
