import {
  Button,
  Icons,
  LinkButton,
  Modal,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { ModalContent, ModalFooter } from './finish-later-modal.styles';
import { dispatchToast, hrosLoginUrl } from '@/utils';
import { useNavigate } from 'react-router-dom';

interface ModalProps {
  open: boolean;
  isLastStep: boolean;
  onCancel: () => void;
}

export const FinishLaterModal = ({
  open,
  isLastStep,
  onCancel,
}: ModalProps) => {
  const navigate = useNavigate();

  const handleCopyLink = async () => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(hrosLoginUrl);
      dispatchToast({
        type: 'success',
        content: 'Link copiado com sucesso!',
      });
    } else {
      dispatchToast({
        type: 'error',
        content: 'Falha ao copiar o link!',
      });
    }
  };

  return (
    <Modal.Root open={open} onClose={() => {}} showClose={false} size="xs">
      <>
        <ModalContent>
          <Typography
            variant="headline6"
            variantColor="var(--color-neutral-20)"
          >
            Tem certeza que deseja sair e não finalizar a{' '}
            {isLastStep ? 'última etapa de contratação?' : 'contratação?'}
          </Typography>
          {isLastStep && (
            <>
              <Typography
                variant="body4"
                variantColor="var(--color-neutral-50)"
              >
                A partir daqui, caso a contratação seja interrompida, você
                poderá acessar a sua conta pelo link de acesso à plataforma
                abaixo com os dados cadastrados de CPF e senha para continuar de
                onde parou.
              </Typography>
              <LinkButton variant="secondary" onClick={handleCopyLink}>
                hros.flashapp.com.br <Icons name="IconCopy" />
              </LinkButton>
            </>
          )}
          {!isLastStep && (
            <Typography variant="body4" variantColor="var(--color-neutral-50)">
              Caso prefira, acesse o{' '}
              <strong>mesmo link que te trouxe até aqui</strong> e na opção
              <strong>"Tenho uma conta Flash”</strong>, insira os dados
              cadastrados de CPF e senha para continuar de onde parou.
            </Typography>
          )}
        </ModalContent>
        <ModalFooter>
          <LinkButton variant="primary" onClick={onCancel}>
            Cancelar
          </LinkButton>
          <Button
            size="large"
            variant="primary"
            onClick={() => navigate('/authentication/login')}
          >
            Sair e não finalizar <Icons name="IconLogout" />
          </Button>
        </ModalFooter>
      </>
    </Modal.Root>
  );
};
