import * as styled from './styles';
import { Icons } from '@flash-tecnologia/hros-web-ui-v2';

export const SignUpErrorPage = () => {
  return (
    <styled.MainContainer>
      <styled.IconContainer>
        <Icons
          name="IconMoodSad"
          fill="none"
          size={63}
          stroke={'1.2px'}
          color={'#FE2B8F'}
        />
      </styled.IconContainer>
      <styled.ContentContainer>
        <styled.Title
          variant={'headline7'}
          children={`Não foi possível localizar seu contrato`}
        />
        <styled.Text
          variant={'body3'}
          children={`Tente novamente mais tarde. Se o problema persistir, entre em contato com a gente pelo e-mail empresa@flashapp.com.br ou telefone (11) 4118-0488`}
        />
      </styled.ContentContainer>
    </styled.MainContainer>
  );
};
