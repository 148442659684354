import styled from 'styled-components';

import { Modal } from '@flash-tecnologia/hros-web-ui-v2';

const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 24px 24px 0px 0px;
`;

const StyledModal = styled(Modal.Root)`
  && {
    .modal-content-area {
      padding-top: 0px;
    }
    @media screen and (max-width: 500px) {
      &.modal-custom-theme .modal-content-area {
        padding: 5px 10px;
      }
    }
  }
`;

export { CloseContainer, StyledModal };
