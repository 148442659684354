import { ThemeProvider } from '@flash-tecnologia/hros-web-ui-v2';
import { QueryClientProvider } from '@tanstack/react-query';

import { ConfigurationContext } from './context';
import AppRouter from './routes';
import { trpc, trpcClient, queryClient } from './api/client';
import { FlagsProvider } from '@flash-tecnologia/feature-flags';
import { env } from './lib/env';

export default function Root() {
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <FlagsProvider
        appName="hros-web-signup"
        url={env.UNLEASH_BENEFITS_URL}
        token={env.UNLEASH_BENEFITS_PROXY_KEY}
        refreshIntervalSeconds={1800}
      >
        <QueryClientProvider client={queryClient}>
          <ConfigurationContext>
            <ThemeProvider>
              <AppRouter />
            </ThemeProvider>
          </ConfigurationContext>
        </QueryClientProvider>
      </FlagsProvider>
    </trpc.Provider>
  );
}
