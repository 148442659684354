import { useCallback, useMemo } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import {
  LinkButton,
  Modal,
  ShapeIcon,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { getFromLS } from '@flash-hros/utility';

import { ButtonsContainer, StyledButton, ModalContent } from './styles';
import { trackEvent } from '../../../../../utils/eventTracking';
import { hrosLoginUrl } from '@utils';

interface modalProps {
  open: boolean;
  isTrial?: boolean;
}

export const FluxEndModal = ({ open, isTrial }: modalProps) => {
  const navigate = useNavigate();

  const userAuth = useMemo(() => getFromLS('userAuth'), []);
  const userInfos = useMemo(() => {
    if (userAuth?.attributes?.name) {
      const userFirstName = userAuth?.attributes?.name.split(' ')[0];
      return { firstName: userFirstName || '' };
    }
  }, [userAuth]);

  const handleNavigation = useCallback(async () => {
    trackEvent('signup_finish_info_start_clicked');
    const redirectTo = isTrial ? '/acquisition/trial' : '/home';

    navigate({
      pathname: '/authentication/access-selection',
      search: createSearchParams({
        redirectTo,
      }).toString(),
    });
  }, []);

  return (
    <Modal.Root open={open} onClose={handleNavigation} showClose={false}>
      <ModalContent>
        <ShapeIcon
          variant="default"
          name="IconCheck"
          color="var(--color-secondary-50)"
          strokeWidth="1.5px"
          size={64}
        />
        <Typography
          variant="body3"
          variantColor="var(--color-secondary-50)"
          weight={700}
        >
          Parabéns
          {userInfos?.firstName ? ', ' + userInfos.firstName + '!' : '!'}
        </Typography>

        <Typography
          variant="headline6"
          variantColor="var(--color-neutral-20)"
          style={{ textAlign: 'center' }}
        >
          Você acaba de fazer uma escolha que facilita todas as outras
        </Typography>
        <Typography
          variant="body4"
          variantColor="var(--color-neutral-50)"
          style={{ textAlign: 'center' }}
        >
          O cadastro da sua empresa foi criado com sucesso! Aproveite para
          configurar a plataforma e começar impulsionar sua gestão de
          colaboradores
        </Typography>
        <ButtonsContainer>
          <LinkButton variant="primary" onClick={() => navigate(hrosLoginUrl)}>
            Agora não
          </LinkButton>
          <StyledButton
            size="large"
            variant="primary"
            children="Configurar plataforma"
            onClick={handleNavigation}
          />
        </ButtonsContainer>
      </ModalContent>
    </Modal.Root>
  );
};
