import styled from 'styled-components';
import {
  Accordion as DSAccordion,
  Button as DSButton,
} from '@flash-tecnologia/hros-web-ui-v2';

export const SectionContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.m};
`;

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
`;

export const FaqContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const Accordion = styled(DSAccordion)`
  background: ${({ theme }) => theme.colors.secondary[99]};

  &&.Mui-expanded {
    background: ${({ theme }) => theme.colors.neutral[95]};
  }

  &&.MuiAccordion-root::before {
    content: unset;
  }
`;

export const Button = styled(DSButton)`
  width: 240px;
  align-self: auto;

  @media screen and (max-width: 450px) {
    width: 100%;
  }
`;
