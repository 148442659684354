import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

const FlashInfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  padding: 38px 120px;
  background-color: #f7f4f6;
  gap: 20px;
  @media screen and (max-width: 1000px) {
    padding: 38px 60px;
  }

  @media screen and (max-width: 420px) {
    flex-direction: column;
    align-items: start;
    padding: 20px;
    gap: 15px;
  }
`;

const FlashContacts = styled.div`
  display: flex;
  gap: 18px;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 5px;
  }
`;
const FlashPhone = styled.div`
  color: #ed718b;
  display: flex;
  align-items: center;
  gap: 3px;
`;

const StyledText = styled(Typography)`
  color: #a19199;
`;
const PhoneText = styled(Typography)`
  color: #ed718b;
`;

export { FlashContacts, FlashPhone, FlashInfoContainer, StyledText, PhoneText };
