import { CardDescription, CardDescriptionTitle, CardItens } from './styled';

interface CardProductsProps {
  products: {
    name: string;
    description: string;
  }[];
}

export const CardProducts = (props: CardProductsProps) => {
  return (
    <div>
      {props?.products?.map((values) => (
        <div key={values.description + values.name}>
          <CardItens>
            <CardDescriptionTitle>{values.name}</CardDescriptionTitle>
            <CardDescription>{values.description}</CardDescription>
          </CardItens>
        </div>
      ))}
    </div>
  );
};
