import { trpc } from '@api/client';

export const useGetCommercialConditions = () => {
  const { mutateAsync, isLoading, error, data } =
    trpc.getCommercialConditions.useMutation({
      trpc: { context: { useCognitoToken: true } },
    });

  return { getCommercialConditions: mutateAsync, data, isLoading, error };
};
