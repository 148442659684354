import { useState, useEffect, useMemo } from 'react';
import {
  Typography,
  Checkbox,
  TextField,
} from '@flash-tecnologia/hros-web-ui-v2';
import { getCep, getFromSS } from '@flash-hros/utility';
import {
  MainContainer,
  TermText,
  ConfirmationContainer,
  MessageContainer,
  FieldsContainer,
  StyledInput,
  HelperTextContainer,
  RequiredText,
} from './company-info.styles';
import { ErrorText, StyledIcon } from '../PersonalInfo/styled';
import { useGetSalesCompany } from '../../hooks/use-get-sales-company';
import { FormikProps } from 'formik';
import { CompanyInfoFormValues } from './company-info.hooks';
import { trackEvent, trackPage } from '@/utils';
import { TermsAndConditionsModal } from '@/components';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useUpdateDealStage } from '../../hooks/use-update-deal-stage';

type CompanyInfoProps = {
  form: FormikProps<CompanyInfoFormValues>;
  companyId?: string;
  dealId?: string;
  registrationNumber?: string;
};

export const CompanyInfo = ({
  form,
  companyId,
  dealId,
  registrationNumber,
}: CompanyInfoProps) => {
  const location = useLocation();
  const { getSalesCompany } = useGetSalesCompany();
  const { updateDealStage } = useUpdateDealStage();
  const companyInfo = useMemo(() => getFromSS('temporaryCompanyInfos'), []);
  const [searchParams] = useSearchParams();

  const [showAddress, setShowAddress] = useState(false);
  const [open, setOpen] = useState(false);
  const [handleInvalidZipCode, setHandleInvalidZipCode] = useState(true);
  const isSales = !!companyId;
  const isDeal = !!dealId;
  const isSelf = location.pathname.includes('self');

  useEffect(() => {
    trackPage('signup_create_company_page');

    const selfSignupToken = searchParams.get('self_signup_token');
    if (selfSignupToken) {
      updateDealStage({ trackingId: selfSignupToken, stage: 'pending-signup' });
    }
  }, []);

  useEffect(() => {
    if (companyInfo === undefined) return;
    form.setValues({
      registrationNumber: companyInfo.registrationNumber,
      legalName: companyInfo.legalName,
      name: companyInfo.name,
      employeesQuantity: companyInfo.employeesQuantity,
      zipCode: companyInfo.address.zipCode,
      street: companyInfo.address.street,
      number: companyInfo.address.number,
      complement: companyInfo.address.complement,
      district: companyInfo.address.district,
      state: companyInfo.address.state,
      city: companyInfo.address.city,
      termChecked: false,
    });
  }, [companyInfo]);

  useEffect(() => {
    if (dealId && registrationNumber) {
      form.setValues({
        registrationNumber,
        legalName: form.values.legalName,
        name: form.values.name,
        employeesQuantity: form.values.employeesQuantity,
        zipCode: form.values.zipCode,
        street: form.values.street,
        number: form.values.number,
        complement: form.values.complement,
        district: form.values.district,
        state: form.values.state,
        city: form.values.city,
        termChecked: false,
      });
      form.setFieldTouched('registrationNumber', true);
    }
  }, [dealId, registrationNumber]);

  useEffect(() => {
    async function getCompanyInfoSales() {
      const companyInfoSales = await getSalesCompany({ companyId: companyId! });
      if (companyInfoSales.value) {
        // TODO: extract  to function
        const zipCode =
          companyInfoSales.value.address?.zipCode?.substr(0, 5) +
          '-' +
          companyInfoSales.value.address?.zipCode.substr(5);

        form.setValues({
          registrationNumber: companyInfoSales.value.registrationNumber,
          legalName: companyInfoSales.value.legalName,
          name: companyInfoSales.value.name,
          employeesQuantity: '',
          street: companyInfoSales.value.address?.street,
          number: companyInfoSales.value.address?.number,
          complement: companyInfoSales.value.address?.complement,
          district: companyInfoSales.value.address?.district,
          state: companyInfoSales.value.address?.state,
          city: companyInfoSales.value.address?.city,
          zipCode,
          termChecked: false,
        });
      }
    }

    if (companyId) {
      getCompanyInfoSales();
    }
  }, []);

  useEffect(() => {
    const parsedZipCode = form?.values?.zipCode?.replace(/[^\d]+/g, '');

    parsedZipCode?.length === 8 &&
      (async () => {
        const zipCodeData = await getCep(parsedZipCode);
        setHandleInvalidZipCode(!!zipCodeData.uf);
        setShowAddress(!!zipCodeData.uf);
        form.setValues({
          registrationNumber: form.values.registrationNumber || '',
          legalName: form.values.legalName || '',
          name: form.values.name || '',
          employeesQuantity: form.values.employeesQuantity || '',
          zipCode: form.values.zipCode || '',
          street: zipCodeData.logradouro,
          number: form.values.number || '',
          complement: form.values.complement || '',
          district: zipCodeData.bairro,
          state: zipCodeData.uf,
          city: zipCodeData.localidade,
          termChecked: false,
        });
      })();

    parsedZipCode?.length !== 8 && setShowAddress(false);
    setHandleInvalidZipCode(true);
    form.setValues({
      registrationNumber: form.values.registrationNumber || '',
      legalName: form.values.legalName || '',
      name: form.values.name || '',
      employeesQuantity: form.values.employeesQuantity || '',
      zipCode: form.values.zipCode || '',
      street: '',
      number: '',
      complement: '',
      district: '',
      state: '',
      city: '',
      termChecked: false,
    });
  }, [form.values.zipCode]);

  return (
    <MainContainer>
      <MessageContainer>
        <Typography
          variant="body3"
          variantColor="var(--color-primary)"
          // TODO: weight={700} is not overriding font-weight from variant
          style={{ fontWeight: 700 }}
          children="Crie a sua empresa"
        />
        <Typography
          variant="headline5"
          children="Informe os dados da sua empresa"
          style={{ maxWidth: '340px' }}
        />
        <Typography
          variant="body3"
          variantColor="var(--color-neutral-50)"
          children="Essas informações são importantes para a criação do contrato inicial da empresa. Você poderá adicionar outros CNPJs posteriormente."
          style={{ maxWidth: '535px' }}
        />
      </MessageContainer>
      <FieldsContainer>
        <RequiredText variant="body3" variantColor="var(--color-neutral-30)">
          <Typography
            variant="body3"
            tag="span"
            variantColor="var(--color-feedback-error-50)"
          >
            *
          </Typography>{' '}
          campo obrigatório
        </RequiredText>
        <TextField
          required
          id="registrationNumber"
          name="registrationNumber"
          label="CNPJ"
          value={form.values.registrationNumber}
          disabled={isSales || isDeal}
          onChange={form.handleChange}
          imaskProps={{ mask: '00.000.000/0000-00' }}
          error={
            form.touched.registrationNumber &&
            Boolean(form.errors.registrationNumber)
          }
        />
        {form.touched.registrationNumber && form.errors.registrationNumber && (
          <HelperTextContainer>
            <StyledIcon size={15} name="IconAlertCircle" fill="none" />
            <ErrorText variant="body4">
              {form.errors.registrationNumber}
            </ErrorText>
          </HelperTextContainer>
        )}
        {(isDeal || isSales) && (
          <HelperTextContainer>
            <Typography
              variant="caption"
              variantColor="var(--color-neutral-50)"
              weight={600}
            >
              O mesmo CNPJ informado à equipe de vendas deve ser usado para
              manter as condições da proposta. Caso o número esteja incorreto,
              entre em contato com o especialista da negociação.
            </Typography>
          </HelperTextContainer>
        )}

        <StyledInput
          required
          id="legalName"
          name="legalName"
          label="Razão Social"
          autoComplete="off"
          value={form.values.legalName}
          disabled={isSales}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          error={form.touched.legalName && Boolean(form.errors.legalName)}
        />
        {form.touched.legalName && form.errors.legalName && (
          <HelperTextContainer>
            <StyledIcon size={15} name="IconAlertCircle" fill="none" />
            <ErrorText variant="body4">{form.errors.legalName}</ErrorText>
          </HelperTextContainer>
        )}

        <StyledInput
          id="name"
          name="name"
          label="Nome fantasia (Opcional)"
          value={form.values.name}
          disabled={isSales}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          error={form.touched.name && Boolean(form.errors.name)}
        />
        {form.touched.name && form.errors.name && (
          <HelperTextContainer>
            <StyledIcon size={15} name="IconAlertCircle" fill="none" />
            <ErrorText variant="body4">{form.errors.name}</ErrorText>
          </HelperTextContainer>
        )}

        {isSelf && (
          <StyledInput
            required
            id="employeesQuantity"
            name="employeesQuantity"
            label="Quantidade de funcionários"
            value={form.values.employeesQuantity}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            error={
              form.touched.employeesQuantity &&
              Boolean(form.errors.employeesQuantity)
            }
          />
        )}
        {isSelf &&
          form.touched.employeesQuantity &&
          form.errors.employeesQuantity && (
            <HelperTextContainer>
              <StyledIcon size={15} name="IconAlertCircle" fill="none" />
              <ErrorText variant="body4">
                {form.errors.employeesQuantity}
              </ErrorText>
            </HelperTextContainer>
          )}

        <StyledInput
          required
          id="zipCode"
          name="zipCode"
          label="CEP"
          value={form.values.zipCode}
          disabled={isSales}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          error={
            (form.touched.zipCode && Boolean(form.errors.zipCode)) ||
            !handleInvalidZipCode
          }
          imaskProps={{ mask: '00000-000' }}
        />
        {form.touched.zipCode && form.errors.zipCode && (
          <HelperTextContainer>
            <StyledIcon size={15} name="IconAlertCircle" fill="none" />
            <ErrorText variant="body4">{form.errors.zipCode}</ErrorText>
          </HelperTextContainer>
        )}
        {!handleInvalidZipCode && (
          <HelperTextContainer>
            <StyledIcon size={15} name="IconAlertCircle" fill="none" />
            <ErrorText variant="body4">
              Por favor, digite um CEP válido.
            </ErrorText>
          </HelperTextContainer>
        )}
        {showAddress && (
          <>
            <StyledInput
              required
              id="street"
              name="street"
              label="Logradouro"
              value={form.values.street}
              disabled={isSales}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              error={form.touched.street && Boolean(form.errors.street)}
            />
            {form.touched.street && form.errors.street && (
              <HelperTextContainer>
                <StyledIcon size={15} name="IconAlertCircle" fill="none" />
                <ErrorText variant="body4">{form.errors.street}</ErrorText>
              </HelperTextContainer>
            )}

            <StyledInput
              required
              id="number"
              name="number"
              label="Número"
              value={form.values.number}
              disabled={isSales}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              error={form.touched.number && Boolean(form.errors.number)}
            />
            {form.touched.number && form.errors.number && (
              <HelperTextContainer>
                <StyledIcon size={15} name="IconAlertCircle" fill="none" />
                <ErrorText variant="body4">{form.errors.number}</ErrorText>
              </HelperTextContainer>
            )}

            <StyledInput
              id="complement"
              name="complement"
              label="Complemento (Opcional)"
              value={form.values.complement}
              disabled={isSales}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
            />

            <StyledInput
              required
              id="district"
              name="district"
              label="Bairro"
              value={form.values.district}
              disabled={isSales}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              error={form.touched.district && Boolean(form.errors.district)}
            />
            {form.touched.district && form.errors.district && (
              <HelperTextContainer>
                <StyledIcon size={15} name="IconAlertCircle" fill="none" />
                <ErrorText variant="body4">{form.errors.district}</ErrorText>
              </HelperTextContainer>
            )}

            <StyledInput
              required
              id="state"
              name="state"
              label="Estado"
              value={form.values.state}
              disabled={isSales}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              inputProps={{ maxLength: 2 }}
              imaskProps={{ mask: /^[A-Za-z]+$/ }}
              error={form.touched.state && Boolean(form.errors.state)}
            />
            {form.touched.state && form.errors.state && (
              <HelperTextContainer>
                <StyledIcon size={15} name="IconAlertCircle" fill="none" />
                <ErrorText variant="body4">{form.errors.state}</ErrorText>
              </HelperTextContainer>
            )}

            <StyledInput
              required
              id="city"
              name="city"
              label="Cidade"
              value={form.values.city}
              disabled={isSales}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              error={form.touched.city && Boolean(form.errors.city)}
            />
            {form.touched.city && form.errors.city && (
              <HelperTextContainer>
                <StyledIcon size={15} name="IconAlertCircle" fill="none" />
                <ErrorText variant="body4">{form.errors.city}</ErrorText>
              </HelperTextContainer>
            )}
          </>
        )}
        <ConfirmationContainer>
          <Checkbox
            checked={!!form.values.termChecked}
            onChange={(e) =>
              form.setFieldValue('termChecked', e.target.checked)
            }
          />

          <Typography
            variant="body3"
            variantColor="var(--color-neutral-50)"
            weight={600}
          >
            Li e aceito os{' '}
            <TermText
              onClick={() => {
                trackEvent('signup_create_company_terms_clicked');
                setOpen(true);
              }}
              children="termos e condições gerais de uso"
            />{' '}
            da Flash
          </Typography>
          <TermsAndConditionsModal open={open} onClose={() => setOpen(false)} />
        </ConfirmationContainer>
      </FieldsContainer>
    </MainContainer>
  );
};
