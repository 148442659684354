import { Icons, TextField, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const ErrorText = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.feedback.error.pure};
    font-size: 12px;
    font-weight: 700;
  }
`;

export const StyledIcon = styled(Icons)`
  && {
    color: ${({ theme }) => theme.colors.feedback.error.pure};
  }
`;

export const StyledInput = styled(TextField)`
  && {
    margin-top: 24px;
  }
`;
