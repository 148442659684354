import { Stepper } from '@flash-tecnologia/hros-web-ui-v2';
import { useEffect, useState } from 'react';

const COMPANY_INFO = 'Dados da empresa';
const ADMIN_INFO = 'Seus dados';
const PHONE_CONFIRMATION = 'Confirmação de celular';
const COMMERCIAL_CONDITIONS = 'Condições comerciais';

const defaultSteps = [COMPANY_INFO, ADMIN_INFO];

interface ProgressTabProps {
  selectedPage: string;
  hasCommercialConditions?: boolean;
  hasPhoneConfirmation?: boolean;
}

export const ProgressTab = ({
  selectedPage,
  hasCommercialConditions = false,
  hasPhoneConfirmation = true,
}: ProgressTabProps) => {
  const [currentStep, setCurrentStep] = useState<number>(0);

  const steps = [
    ...defaultSteps,
    ...(hasPhoneConfirmation ? [PHONE_CONFIRMATION] : []),
    ...(hasCommercialConditions ? [COMMERCIAL_CONDITIONS] : []),
  ];

  useEffect(() => {
    switch (selectedPage) {
      case 'create-company':
        setCurrentStep(steps.indexOf(COMPANY_INFO));
        break;
      case 'create-admin':
        setCurrentStep(steps.indexOf(ADMIN_INFO));
        break;
      case 'admin-info':
        setCurrentStep(steps.indexOf(ADMIN_INFO));
        break;
      case 'validate-phone':
        setCurrentStep(steps.indexOf(PHONE_CONFIRMATION));
        break;
      case 'commercial-conditions':
        setCurrentStep(steps.indexOf(COMMERCIAL_CONDITIONS));
        break;
    }
  }, [selectedPage]);

  return <Stepper steps={steps} activeStep={currentStep} />;
};
